import * as React from 'react'

import { styled } from '@mui/material'
import { StaticImage } from 'gatsby-plugin-image'

import { SeoHeaders } from 'shared/components'
import { BaseSeoSection, SeoSection } from 'shared/components/seo_section'
import { TestimonialsSection } from 'shared/components/testimonials'

import BadgesDisplay from '../components/badges_display'
import BlockchainSecuritySection from '../components/blockchain_security_section'
import CeDeFiSection from '../components/cedefi_section'
import CreditCardSection from '../components/credit_card_section'
import DemoSection from '../components/demo_section'
import LandingTitle from '../components/landing_title'
import StepsSection from '../components/steps_section'
import { testimonialsData } from '../data/testimonials'
import BitcoinWool from '../images/landing/bitcoin-wool.svg'
import CryptoDiamond from '../images/landing/crypto-diamond.svg'
import CryptoYarns from '../images/landing/crypto-yarns.svg'
import Layout from '../layouts/landing'

const StrikedText = styled('span')(() => ({
  WebkitTextFillColor: 'initial',
  display: 'inline-block',
  position: 'relative',
  '::after': {
    content: '""',
    position: 'absolute',
    top: '54%',
    width: '103%',
    left: 0,
    height: '3px',
    background: 'var(--color-mainTitleGradient)',
  },
}))

const LandingIndex = () => {
  return (
    <Layout>
      <SeoHeaders
        description={(
          'Custodia y rentabilidad de criptomonedas.'
          + ' Compra, transfiere y protege tu bitcoin, ethereum, cardano, polkadot y stablecoins.'
          + ' Seguridad y blockchain.'
        )}
        schemaMarkup={{
          '@context': 'https://schema.org',
          '@type': 'WebPage',
          'name': 'Landing Page de Sugarblock',
          'url': 'https://sugarblock.io',
          'description': 'Custodia y rentabilidad de criptomonedas. Compra, transfiere y protege tu bitcoin,' +
     'ethereum, cardano, polkadot y stablecoins. Seguridad y blockchain.',
          'publisher': {
            '@type': 'Organization',
            'name': 'Sugarblock',
            'logo': 'https://sugarblock.io/images/mail-header.png',
          },
        }}
      />
      <LandingTitle />
      <TestimonialsSection
        counterStart={1000}
        counterLimit={25000}
        testimonials={testimonialsData}
      />
      <BaseSeoSection
        reversed
        maxWidth='lg'
        seoTitle='Una cuenta internacional 100% gratuita'
        seoSubtitle={
          'Compra, utiliza y genera recompensas en dólares y criptomonedas.'
          + ' Ahorra en piloto automático y protégete de la inflación.'
        }
        spacing={1}
        imageWidth={6}
        imageComponent={(
          <StaticImage
            src='../images/landing/purchase.png'
            alt='Aplicación Sugarblock comprando dólares'
            placeholder='none'
          />
        )}
      />
      <BadgesDisplay />
      <CreditCardSection />
      <StepsSection />
      <CeDeFiSection />
      <BlockchainSecuritySection />
      <SeoSection
        reversed
        maxWidth='md'
        seoTitle='Todo se hereda'
        seoSubtitle={(
          <React.Fragment>
            Tú decides a quién le tenemos que entregar tus criptomonedas en caso de algún evento catastrófico. En el
            código confiamos.
          </React.Fragment>
        )}
        imageSrc={CryptoYarns}
        imageAlt='Criptomonedas entrelazadas'
      />
      <SeoSection
        maxWidth='md'
        seoTitle={(
          <React.Fragment>
            <StrikedText>
              Fee&nbsp;de&nbsp;depósito
            </StrikedText>
            <StrikedText>
              Fee&nbsp;de&nbsp;mantención
            </StrikedText>
            <StrikedText>
              Cosas&nbsp;raras
            </StrikedText>
          </React.Fragment>
        )}
        seoSubtitle='En Sugarblock desarrollamos una estructura que elimina fees administrativos'
        imageSrc={CryptoDiamond}
        imageAlt='Token de bitcoin dentro de un diamante'
      />
      <SeoSection
        reversed
        maxWidth='md'
        seoTitle='No más palabras amargas'
        seoSubtitle={(
          <React.Fragment>
            DeFi, Yield&nbsp;Farming, POS, POW, Blockchain, Private&nbsp;Keys, Beacon&nbsp;Chain, Block, ConsenSys,
            Whale, Shark, Elon&nbsp;Musk, DAO, Dapp, DEX, ERC-20, Token, Halving, ICO, Mainnet, Market&nbsp;Cap,
            Node, Oracle, Satoshi&nbsp;Nakamoto
          </React.Fragment>
        )}
        seoBody='Nosotros entendemos estas palabras extrañas para que tú no tengas que hacerlo'
        imageSrc={BitcoinWool}
        imageAlt='Logo Bitcoin bordado con hilos'
      />
      <DemoSection />
    </Layout>
  )
}

export default LandingIndex
