import * as React from 'react'

import { TouchApp } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'

import { AssetBadge, Subtitle, Title } from 'shared/components'
import { FirebaseContext } from 'shared/contexts'
import { mapBaseApys } from 'shared/services'

import FloatingBadges from './floating_badges'
import { cryptoData } from '../../data/crypto'
import MeshBackground from '../../images/landing/mesh-background.svg'
import { dynamicColors } from '../../mui-theme-config'
import Carousel from '../carousel/carousel'
import { Slide, SlideButton } from '../carousel/slide'

import type { CryptoName } from '../../data/crypto'
import type { BaseApy } from 'shared/queries'

const edgeGradient = [
  `${dynamicColors.background.dark} 3%`,
  'transparent 10% 20%',
  `${dynamicColors.background.dark} 35% 65%`,
  'transparent 80% 90%',
  `${dynamicColors.background.dark} 97%`,
].join(',')

type MarketAsset = {
  id: string
  name: CryptoName
  symbol: string
}

type QueryProps = {
  guita: {
    marketAssets: MarketAsset[]
    baseApys: BaseApy[]
  }
}

const BadgesDisplay = () => {
  const [open, setOpen] = React.useState(false)
  const [slideIndex, setSlideIndex] = React.useState(0)
  const { analytics, logEvent } = React.useContext(FirebaseContext)

  const data: QueryProps = useStaticQuery(graphql`
    query BadgesDisplayMarketAssets {
      guita {
        marketAssets {
          id
          name
          symbol
        }
        baseApys {
          id
          marketAssetId
          value
        }
      }
    }
  `)

  const marketAssets = data.guita.marketAssets
  const baseApysMap = mapBaseApys(data.guita.baseApys)

  const handleBadgeClick = (name: string) => {
    const index = marketAssets.findIndex((asset) => asset.name === name)
    setSlideIndex(index)
    setOpen(true)

    if (!analytics) {
      return
    }

    logEvent(analytics, 'badge_click', { crypto_name: marketAssets[index].name })
    logEvent(analytics, 'badges_carousel_open')
  }

  const handleClose = () => {
    setOpen(false)

    if (!analytics) {
      return
    }

    logEvent(analytics, 'badges_carousel_close')
  }

  return (
    <React.Fragment>
      <Stack
        spacing={3}
        alignItems='center'
        sx={{ pt: 6, pb: 3, textAlign: 'center' }}
      >
        <Title
          colorful
          px={2}
        >
          Con Sugarblock tus criptos hacen más criptos
        </Title>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: 'min(60vh, 650px)',
            overflow: 'hidden',
            textAlign: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              position: 'absolute',
              top: '6%',
              width: '100%',
              height: '88%',
              backgroundColor: dynamicColors.background.dark,
              backgroundImage: `linear-gradient(${edgeGradient}), url('${MeshBackground}')`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderTop: 1,
              borderColor: 'hsl(var(--color-shadowColor) / 0.72)',
              boxShadow: theme.shadows[8],
            })}
          >
          </Box>
          <FloatingBadges
            onBadgeClick={handleBadgeClick}
            style={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        </Box>
        <Subtitle
          color='text.secondary'
          fontWeight={400}
          px={2}
        >
          <TouchApp />
          Presiona cualquiera para&nbsp;ver&nbsp;más&nbsp;detalles
        </Subtitle>
      </Stack>
      <Carousel
        open={open}
        onClose={handleClose}
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
        overscanSlideAfter={3}
      >
        {marketAssets.map((asset, index) => (
          <Slide
            key={index}
            color={cryptoData[asset.name].color}
            media={(
              <AssetBadge
                animated
                symbol={asset.symbol}
              />
            )}
            mediaStyles={{
              height: '30vh',
              pl: '3vh',
              pt: '3vh',
            }}
          >
            <Stack
              height='100%'
              direction='column'
              alignItems='center'
              justifyContent='space-between'
              textAlign='center'
              spacing={3}
              p={4}
            >
              <Title id='modal-title'>
                {asset.name}
                <span style={{ fontSize: '0.75em', fontWeight: 400 }}> / {baseApysMap[asset.id]}% APY</span>
              </Title>
              <Typography
                id='modal-description'
                variant='body2'
                textAlign='center'
              >
                {cryptoData[asset.name].description}
              </Typography>
              <SlideButton
                slideColor={cryptoData[asset.name].color}
                href='/about/'
              >
                VER MÁS INFORMACIÓN
              </SlideButton>
            </Stack>
          </Slide>
        ))}
      </Carousel>
    </React.Fragment>
  )
}

export default BadgesDisplay
