import * as React from 'react'

import { Box, Button, Stack, Theme, darken, lighten, useTheme } from '@mui/material'

import type { ButtonProps, SxProps } from '@mui/material'

export const getColorShade = (theme: Theme, color: string) => (
  (theme.palette.mode === 'light') ? lighten(color, 0.62) : darken(color, 0.5)
)

export const getContrastText = (theme: Theme, color: string) => (
  theme.palette.getContrastText(color)
)

type SlideButtonProps = ButtonProps & {
  slideColor: string
}

export const SlideButton = ({
  slideColor,
  children,
  ...other
}: SlideButtonProps) => {
  const theme = useTheme()
  const backgroundColor = getColorShade(theme, slideColor)
  const contrastColor = theme.palette.getContrastText(backgroundColor)

  return (
    <Button
      {...other}
      variant='contained'
      color='inherit'
      sx={{
        bottom: 0,
        fontWeight: 'medium',
        color: contrastColor,
        backgroundColor: backgroundColor,
        '&:hover': {
          backgroundColor: backgroundColor,
        },
      }}
    >
      {children}
    </Button>
  )
}

type SlideProps = {
  color: string
  media: React.ReactNode
  mediaStyles?: SxProps<Theme>
  children: React.ReactNode
  childrenStyles?: SxProps<Theme>
}

export const Slide = ({
  color,
  media,
  mediaStyles = {},
  children,
  childrenStyles = {},
}: SlideProps) => {
  const theme = useTheme()

  return (
    <Stack
      direction='column'
      height='100%'
    >
      <Box
        sx={{
          backgroundColor: getColorShade(theme, color),
          ...mediaStyles,
        }}
      >
        {media}
      </Box>
      <Box
        sx={{
          flex: 1,
          color: getContrastText(theme, color),
          backgroundColor: color,
          ...childrenStyles,
        }}
      >
        {children}
      </Box>
    </Stack>
  )
}

export default Slide
