/* eslint-disable max-len */
import * as React from 'react'

import { Box, Button } from '@mui/material'

import { BaseSeoSection } from 'shared/components'

const CreditCard = () => (
  <svg
    height='100%'
    version='1.1'
    viewBox='0 0 53.98 85.6'
    xmlns='http://www.w3.org/2000/svg'
  >
    <defs>
      <pattern
        id='cubes'
        width='29'
        height='50.012'
        patternTransform='scale(.3) translate(17, -7.5)'
        patternUnits='userSpaceOnUse'
      >
        <path
          d='M14.498 16.858L0 8.488.002-8.257l14.5-8.374L29-8.26l-.002 16.745zm0 50.06L0 58.548l.002-16.745 14.5-8.373L29 41.8l-.002 16.744zM28.996 41.8l-14.498-8.37.002-16.744L29 8.312l14.498 8.37-.002 16.745zm-29 0l-14.498-8.37.002-16.744L0 8.312l14.498 8.37-.002 16.745z'
          fill='none'
          opacity='.16'
          stroke='var(--color-text)'
        />
      </pattern>
      <linearGradient
        id='golden'
        gradientTransform='scale(.87073 1.1485)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          stopColor='#f5eacc'
          offset='0'
        />
        <stop
          stopColor='#e0c677'
          offset='.8'
        />
        <stop
          stopColor='#ebd8a0'
          offset='1.1'
        />
      </linearGradient>
      <linearGradient
        id='colorful'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          stopColor='#b124c6'
          offset='.1'
        >
          <animate
            attributeName='stop-color'
            calcMode='paced'
            dur='4s'
            repeatCount='indefinite'
            values='#b124c6; #8f27be; #702ab7; #b124c6;'
          />
        </stop>
        <stop
          stopColor='#8f27be'
          offset='.4'
        >
          <animate
            attributeName='stop-color'
            calcMode='paced'
            dur='4s'
            repeatCount='indefinite'
            values='#8f27be; #702ab7; #b124c6; #8f27be;'
          />
        </stop>
        <stop
          stopColor='#702ab7'
          offset='1'
        >
          <animate
            attributeName='stop-color'
            calcMode='paced'
            dur='4s'
            repeatCount='indefinite'
            values='#702ab7; #b124c6; #8f27be; #702ab7;'
          />
        </stop>
      </linearGradient>
    </defs>
    <rect
      x='0'
      y='0'
      width='100%'
      height='100%'
      fill='url(#cubes)'
    />
    <g
      className='chip'
      transform='matrix(.15077 0 0 .15243 27.758 9.5255)'
    >
      <rect
        width='58'
        height='76.5'
        rx='9'
        ry='9'
        fill='url(#golden)'
      />
      <path
        d='m58 54.05m-58-28.33c2.11 0.21 3.75 0.47 4.03 0.74 2.92 3.64 5.83 7.28 8.67 10.98 0.77 1 1.52 1.72 2.89 1.47 0.95-0.18 1.28 0.3 1.27 1.25-0.04 4.03-0.01 8.03-0.02 12.07h25.08v-25.32h-26.77s-1.95 0-1.95-1.96c-0.4-1.85-0.4-14.9-0.34-24.95m33.1 0c0 8.65 0.01 18.65-0.01 24.19-0.01 2.9 0.27 2.55-2.38 2.84v12.17c4.69-3.07 7.28-8.53 11.07-12.63 0.36-0.51 1.66-0.85 3.36-1.07m0 28.55c-0.55 0-0.89 0.01-0.89 0.01-2.46 0.26-9.33-10.83-12.02-13.2-0.41-0.36-1.57 0.2-1.57 0.2s-0.02 7.37-0.02 11.13c2.56 0.32 2.56 0.32 2.56 2.77v21.04m-32.96 0c0.01-8.57 0.04-17.77 0.07-22.53-0.01-0.82 0.36-1.2 1.17-1.17 0.28 0.01 1.03-0.06 1.03-0.06l0.05-11.81s-1.73 0.15-2.12 0.72-6.16 7.63-9.18 11.5c-0.22 0.2-1.93 0.46-4.12 0.7'
        fill='none'
        opacity='1'
        stroke='#808080'
        strokeWidth={1}
      />
    </g>
    <g fill='url(#colorful)'>
      <g
        className='logo'
        transform='matrix(.13283 0 0 .13283 20.7 77.4)'
      >
        <path d='m210.34 13.76a13.14 13.14 0 0 1 2.82-2.46 8.64 8.64 0 0 1 7.16-0.91 5.36 5.36 0 0 1 3.85 5.1 8.69 8.69 0 0 1-3.6 7.63 13 13 0 0 1-2 1.33c-0.39 0.2-0.42 0.41-0.29 0.79 1.11 3.42 2.2 6.85 3.32 10.28 0.15 0.46 0.12 0.6-0.42 0.59h-7.27a0.56 0.56 0 0 1-0.65-0.48c-1-3.72-2.06-7.44-3.12-11.15-0.14-0.47 0-0.59 0.4-0.75a12.5 12.5 0 0 0 3.7-2.11 5.38 5.38 0 0 0 2-4.15 1.74 1.74 0 0 0-2.56-1.78 5.81 5.81 0 0 0-2.34 1.69 16.4 16.4 0 0 0-1.71 2.35 2.47 2.47 0 0 0-0.31 0.95q-0.86 6.33-1.73 12.66c-0.1 0.76-0.21 1.53-0.29 2.3 0 0.33-0.15 0.47-0.52 0.47h-7c-0.4 0-0.47-0.13-0.42-0.48l1.59-11.36q0.87-6.18 1.73-12.36l1.47-10.59c0-0.24 0-0.48 0.41-0.52 2.37-0.21 4.75-0.45 7.11-0.71 0.52-0.06 0.59 0.1 0.53 0.56-0.6 4.2-1.17 8.4-1.75 12.59a4.47 4.47 0 0 0-0.11 0.52z' />
        <path d='m11.13 37a14.49 14.49 0 0 1-10.71-4.29c-0.57-0.58-0.56-0.58 0-1.16 1.23-1.18 2.44-2.36 3.58-3.55 0.29-0.29 0.45-0.29 0.75 0a8.92 8.92 0 0 0 4.83 2.63 6.61 6.61 0 0 0 4.61-0.63 3.94 3.94 0 0 0 0.46-6.19 15.12 15.12 0 0 0-3.73-2.07 19.37 19.37 0 0 1-3.5-1.89c-2.85-2-3.77-4.74-3.41-8 0.58-5.23 4.29-8.51 9.47-9.33a14.9 14.9 0 0 1 9.29 1.25 13 13 0 0 1 3.53 2.65c0.17 0.18 0.2 0.29 0 0.49-1.24 1.25-2.48 2.51-3.7 3.79-0.25 0.27-0.38 0.11-0.56 0a7.71 7.71 0 0 0-4.92-2.3 5 5 0 0 0-3.46 0.79 3 3 0 0 0 0 4.9 22.33 22.33 0 0 0 4 2.06 17.94 17.94 0 0 1 3.59 2.1 7.93 7.93 0 0 1 3.14 6.2c0.36 6.79-4.4 11.49-10.57 12.3-0.82 0.12-1.82 0.17-2.69 0.25z' />
        <path d='m38.85 32.86c-0.44 0.5-0.92 1.08-1.44 1.61a7.49 7.49 0 0 1-5.65 2.53 5.05 5.05 0 0 1-5.18-4.58 17.67 17.67 0 0 1 0.31-5c0.52-3.86 1.07-7.72 1.61-11.59 0.2-1.51 0.41-3 0.61-4.53 0-0.31 0.15-0.43 0.49-0.43h7c0.39 0 0.44 0.1 0.39 0.48q-1.17 8.25-2.29 16.53a7 7 0 0 0 0 1.37 1 1 0 0 0 1.47 0.93 5.13 5.13 0 0 0 2-1.67 5.88 5.88 0 0 0 1.21-3.39c0.62-4.62 1.28-9.22 1.91-13.83 0-0.34 0.17-0.42 0.49-0.42h7c0.34 0 0.44 0.06 0.38 0.43q-0.88 6.27-1.75 12.53c-0.54 3.85-1.1 7.69-1.61 11.53-0.08 0.59-0.23 0.83-0.89 0.81h-5.48c-0.45 0-0.62-0.08-0.59-0.58 0.05-0.96 0.01-1.86 0.01-2.73z' />
        <path d='m179.51 26.09a19.38 19.38 0 0 1 2.65-9.92 12 12 0 0 1 11.84-6.17 9.83 9.83 0 0 1 6.32 2.85c0.28 0.27 0.34 0.43 0.05 0.75q-1.64 1.85-3.22 3.75c-0.23 0.28-0.35 0.29-0.64 0a5 5 0 0 0-4.14-1.21 3.92 3.92 0 0 0-2.94 2.28 17.14 17.14 0 0 0-1.52 9.61c0.27 2.22 2.1 3.14 4.36 2.4a9.58 9.58 0 0 0 2.29-1.13c0.29-0.19 0.41-0.15 0.57 0.14 0.81 1.41 1.62 2.81 2.45 4.21 0.14 0.24 0.12 0.36-0.11 0.52-3.9 2.8-8.11 3.66-12.63 1.77-2.83-1.18-4.33-3.54-5-6.47a18 18 0 0 1-0.33-3.38z' />
        <path d='m147.89 37a5.83 5.83 0 0 1-4.14-1.4 5.34 5.34 0 0 1-1.6-3.84 28 28 0 0 1 0.54-5c0.55-4.12 1.14-8.24 1.72-12.35s1.16-8.41 1.75-12.52c0.15-1 0.17-1 1.15-1.16 2.13-0.22 4.26-0.41 6.39-0.64 0.45 0 0.54 0.08 0.48 0.5q-0.94 6.65-1.86 13.29l-1.83 13.35c-0.09 0.67-0.19 1.34-0.27 2-0.14 1.05 0.3 1.49 1.31 1.28 0.38-0.08 0.47 0 0.51 0.36 0.18 1.51 0.37 3 0.58 4.54 0.06 0.44 0 0.66-0.48 0.82a13.61 13.61 0 0 1-4.25 0.77z' />
        <path d='m109.61 15.1a11.81 11.81 0 0 1 2.39-3.35 5.91 5.91 0 0 1 5.79-1.43c0.39 0.1 0.5 0.22 0.37 0.64-0.72 2.26-1.4 4.53-2.07 6.8-0.12 0.4-0.28 0.47-0.68 0.38a3.75 3.75 0 0 0-4.69 2 16.85 16.85 0 0 0-2.25 6.7c-0.34 2.92-0.81 5.84-1.21 8.76-0.05 0.37-0.2 0.47-0.57 0.47h-6.92c-0.41 0-0.49-0.12-0.43-0.51q1.25-8.94 2.48-17.88c0.3-2.14 0.61-4.27 0.9-6.41 0-0.36 0.14-0.5 0.53-0.49h5.84c0.39 0 0.51 0.1 0.5 0.5 0 1.24 0.02 2.45 0.02 3.82z' />
        <path d='m73.55 12.31a19.23 19.23 0 0 0-9.06-2.31c-5.25 0-9.23 2.12-11.8 6.71a19.71 19.71 0 0 0-2.25 7.94 16.5 16.5 0 0 0 0.56 6.65 6.75 6.75 0 0 0 10.35 3.64 9.92 9.92 0 0 0 2.1-1.94c-0.18 1.22-0.32 2.3-0.5 3.38-0.54 3.26-2.49 4.58-5.85 4.24a13 13 0 0 1-4.66-1.54c-0.32-0.17-0.44-0.15-0.6 0.18q-1.12 2.2-2.3 4.36c-0.16 0.3-0.1 0.42 0.17 0.59a16.27 16.27 0 0 0 11.64 2.29 9.91 9.91 0 0 0 7.65-5.5 22.42 22.42 0 0 0 2-6.87l1.5-10.59q0.73-5.23 1.48-10.47a0.62 0.62 0 0 0-0.43-0.76zm-8 4.06c-0.31 2.13-0.6 4.27-0.91 6.4-0.17 1.22-0.38 2.43-0.5 3.65a3.11 3.11 0 0 1-0.73 1.7 7 7 0 0 1-1.85 1.75 1.92 1.92 0 0 1-3.06-1.37 13 13 0 0 1-0.15-2.44 18.77 18.77 0 0 1 1.65-7.53 4.34 4.34 0 0 1 3.11-2.78 5.41 5.41 0 0 1 2.12 0.06c0.33 0.06 0.43 0.19 0.37 0.56z' />
        <path d='m139.87 14.56a6.19 6.19 0 0 0-7.36-4.49 8.62 8.62 0 0 0-4.94 2.72c0-0.34 0-0.53 0.06-0.72q0.81-5.84 1.61-11.65c0-0.34 0-0.45-0.38-0.41-2.33 0.27-4.66 0.54-7 0.76-0.48 0-0.61 0.23-0.67 0.66-0.27 2.12-0.58 4.24-0.87 6.36-0.62 4.41-1.22 8.82-1.84 13.24s-1.23 8.82-1.86 13.23a0.62 0.62 0 0 0 0.46 0.79 23.34 23.34 0 0 0 11.22 1.8 13.12 13.12 0 0 0 5.48-1.68 11.66 11.66 0 0 0 4.59-5.46 23.53 23.53 0 0 0 2.06-8.71 17.62 17.62 0 0 0-0.56-6.44zm-7.42 5.28a23.5 23.5 0 0 1-1.38 7.77 8.79 8.79 0 0 1-1.17 2.12c-1.35 1.74-2.93 1.54-4.61 1-0.25-0.07-0.2-0.25-0.17-0.43q0.81-5.85 1.63-11.7a0.64 0.64 0 0 1 0.06-0.28 5.64 5.64 0 0 1 3-2.41 1.87 1.87 0 0 1 2.43 1.47 4.11 4.11 0 0 1 0.13 0.7 11 11 0 0 1 0.08 1.76z' />
        <path d='m98.47 13.66c0.15-1.09 0.17-1.09-0.8-1.6-0.38-0.2-0.78-0.39-1.18-0.56a17.5 17.5 0 0 0-10.49-1.25 12.16 12.16 0 0 0-8.78 7.18 22.8 22.8 0 0 0-2 11.86 9.44 9.44 0 0 0 1.88 5.24 7.09 7.09 0 0 0 9.79 0.94 12.84 12.84 0 0 0 2.23-2.47 1.5 1.5 0 0 1 0.11 0.17c1 2.36 2.94 3.23 5.28 3.55a0.48 0.48 0 0 0 0.59-0.33c0.67-1.5 1.36-3 2-4.49 0.07-0.15 0.2-0.31 0-0.45-0.93-0.56-0.93-1.45-0.8-2.37q1.07-7.69 2.17-15.42zm-8.23 2.91c-0.22 1.63-0.46 3.25-0.69 4.88-0.29 2-0.59 4-0.86 6.05a2.9 2.9 0 0 1-0.69 1.43 6.51 6.51 0 0 1-1.85 1.81 1.79 1.79 0 0 1-2.84-1.13 8 8 0 0 1-0.23-2.48 22.88 22.88 0 0 1 1.44-8 5.34 5.34 0 0 1 2.48-3.13 4.31 4.31 0 0 1 3-0.17c0.5 0.17 0.27 0.49 0.24 0.74z' />
        <path d='m177.43 17.26a8.42 8.42 0 0 0-5.81-6.77 12.91 12.91 0 0 0-7-0.18 11.57 11.57 0 0 0-7.9 6.5 20.61 20.61 0 0 0-2 12c0.54 4.37 3 7.08 7.07 7.87 5.4 1 10.46-0.78 13.42-6a21.46 21.46 0 0 0 2.51-10.15 16 16 0 0 0-0.29-3.27zm-9.18 10.94a4.81 4.81 0 0 1-1.52 2.24 2.77 2.77 0 0 1-2.69 0.48 2.27 2.27 0 0 1-1.38-2.06 17.43 17.43 0 0 1 0-4.27 24.64 24.64 0 0 1 1.06-5.23 5.32 5.32 0 0 1 1.93-3 2.37 2.37 0 0 1 3.91 1.72 12.48 12.48 0 0 1 0.1 1.83 25.82 25.82 0 0 1-1.41 8.29z' />
      </g>
      <g
        strokeWidth='.26458'
        transform='matrix(1 0 0 1 0 -1)'
      >
        <path d='m4.4196 52.857q0.1397 0 0.25823-0.03387t0.2032-0.09737q0.088899-0.0635 0.13547-0.15663 0.0508-0.09313 0.0508-0.21166 0-0.23283-0.18627-0.36406-0.18203-0.13546-0.55033-0.13546-0.046566 0-0.11007 0.0042-0.059266 0-0.1143 0.0042-0.063499 0.0042-0.13123 0.0042v-1.5494h1.5325v0.508h-1.0583v0.57996q0.033866 0 0.067733-0.0042 0.029633 0 0.055033 0 0.029633-0.0042 0.0508-0.0042 0.21166 0 0.3937 0.0635 0.18203 0.05927 0.3175 0.18203 0.13547 0.12276 0.20743 0.30056 0.076199 0.17356 0.076199 0.38523 0 0.24976-0.093133 0.44026-0.093133 0.18627-0.254 0.31326-0.16087 0.127-0.37253 0.19473-0.21166 0.0635-0.45296 0.0635-0.093133 0-0.19897-0.02117-0.10583-0.01693-0.20743-0.04233-0.1016-0.02963-0.1905-0.0635-0.084666-0.03387-0.14393-0.0635l0.1778-0.44026q0.071966 0.04233 0.22013 0.09313 0.1524 0.0508 0.3175 0.0508z' />
        <path d='m8.3327 52.459h-0.508v0.82549h-0.51223v-0.82549h-1.2827v-0.37676l1.342-1.7822h0.45296v1.7187h0.508zm-1.0202-1.3801-0.73659 0.93979h0.73659z' />
        <path d='m8.5926 51.248q0-0.2159 0.071966-0.39793 0.076199-0.18203 0.20743-0.30903 0.13547-0.13123 0.32173-0.2032 0.1905-0.0762 0.42333-0.0762 0.23706 0 0.4318 0.07197 0.19473 0.07197 0.33443 0.2159 0.1397 0.1397 0.2159 0.35136 0.0762 0.20743 0.0762 0.47836 0 0.49106-0.127 0.84666-0.127 0.3556-0.3429 0.59266-0.21167 0.23706-0.4953 0.36406-0.28363 0.127-0.59266 0.16086l-0.11853-0.4318q0.23283-0.02963 0.4191-0.1143 0.1905-0.08467 0.3302-0.20743 0.1397-0.127 0.2286-0.28363 0.0889-0.16086 0.1143-0.3429-0.0889 0.0889-0.2286 0.14393-0.1397 0.0508-0.33443 0.0508h-0.00847q-0.16087 0-0.32596-0.05503-0.1651-0.055036-0.30056-0.16934-0.13123-0.1143-0.2159-0.28363-0.084666-0.17356-0.084666-0.40216zm0.5334-0.029633q0 0.11853 0.042333 0.21166 0.046566 0.0889 0.11853 0.1524 0.071966 0.05927 0.1651 0.09313 0.097366 0.02963 0.19897 0.02963 0.17357 0 0.30056-0.05503 0.127-0.05503 0.18627-0.13547 0.0127-0.0889 0.0127-0.16933 0-0.11853-0.03387-0.23283t-0.1016-0.2032q-0.067733-0.0889-0.16933-0.1397-0.1016-0.05503-0.23283-0.05503-0.20743 0-0.34713 0.13546-0.1397 0.13123-0.1397 0.3683z' />
        <path d='m11.287 52.819h0.6477v-1.85l-0.5588 0.42756-0.254-0.33866 0.96519-0.73659h0.37253v2.4976h0.635v0.46566h-1.8076z' />
        <path d='m17.039 52.366q0 0.20743-0.08043 0.38523-0.0762 0.1778-0.21166 0.30903t-0.32596 0.20743q-0.18626 0.0762-0.40216 0.0762-0.22013 0-0.41486-0.06773-0.1905-0.07197-0.33443-0.21166-0.14393-0.1397-0.2286-0.35136-0.08467-0.21166-0.08467-0.49106 0-0.4191 0.14393-0.76199 0.14817-0.3429 0.37676-0.59266 0.2286-0.254 0.51223-0.4064 0.28363-0.15663 0.56303-0.19897l0.12277 0.43603q-0.22436 0.04233-0.4191 0.1397-0.19473 0.09313-0.34713 0.2286-0.1524 0.13547-0.25823 0.30056-0.1016 0.1651-0.1397 0.3429 0.08467-0.10583 0.254-0.1778 0.16933-0.0762 0.37253-0.0762 0.1905 0 0.3556 0.0635 0.1651 0.05927 0.28363 0.1778 0.12277 0.1143 0.1905 0.28363 0.07197 0.16933 0.07197 0.38523zm-0.5334 0.02117q0-0.2286-0.1397-0.34713-0.13546-0.11853-0.3683-0.11853-0.16933 0-0.3048 0.0762-0.13546 0.0762-0.19896 0.1778-0.0085 0.04657-0.0127 0.10583 0 0.05503 0 0.0889 0 0.1016 0.03387 0.19473 0.03387 0.09313 0.09737 0.1651 0.06773 0.06773 0.16086 0.11007 0.09313 0.0381 0.2159 0.0381 0.09737 0 0.1905-0.03387 0.09313-0.0381 0.1651-0.1016 0.07197-0.0635 0.1143-0.1524 0.04657-0.09313 0.04657-0.2032z' />
        <path d='m19.415 53.284h-1.8881v-0.41063q0.0508-0.04233 0.127-0.11007 0.0762-0.07197 0.16933-0.16087 0.09737-0.0889 0.2032-0.19473 0.10583-0.10583 0.20743-0.22013 0.14393-0.16086 0.24976-0.2921 0.11007-0.13123 0.1778-0.24553 0.07197-0.11853 0.10583-0.2286 0.03387-0.1143 0.03387-0.23283 0-0.08467-0.0381-0.1651-0.0381-0.08043-0.10583-0.1397-0.06773-0.0635-0.1651-0.09737-0.09313-0.0381-0.20743-0.0381-0.1524 0-0.2794 0.04657-0.12276 0.04657-0.25823 0.15663l-0.22014-0.38946q0.1778-0.127 0.3937-0.21166 0.22013-0.0889 0.50376-0.0889 0.18203 0 0.34713 0.0508 0.16933 0.0508 0.29633 0.1524 0.13123 0.09737 0.20743 0.24553 0.0762 0.14817 0.0762 0.3429 0 0.13546-0.0254 0.254-0.02117 0.1143-0.07197 0.23283-0.04657 0.1143-0.127 0.23706-0.0762 0.12277-0.18626 0.2667-0.08467 0.11007-0.18626 0.22436-0.09737 0.11007-0.19473 0.21166-0.09737 0.1016-0.19473 0.1905-0.09313 0.0889-0.16933 0.14817h1.2192z' />
        <path d='m20.678 52.857q0.1397 0 0.25823-0.03387 0.11853-0.03387 0.2032-0.09737 0.0889-0.0635 0.13546-0.15663 0.0508-0.09313 0.0508-0.21166 0-0.23283-0.18626-0.36406-0.18203-0.13546-0.55033-0.13546-0.04657 0-0.11007 0.0042-0.05927 0-0.1143 0.0042-0.0635 0.0042-0.13123 0.0042v-1.5494h1.5325v0.508h-1.0583v0.57996q0.03387 0 0.06773-0.0042 0.02963 0 0.05503 0 0.02963-0.0042 0.0508-0.0042 0.21166 0 0.3937 0.0635 0.18203 0.05927 0.3175 0.18203 0.13546 0.12276 0.20743 0.30056 0.0762 0.17356 0.0762 0.38523 0 0.24976-0.09313 0.44026-0.09313 0.18627-0.254 0.31326-0.16086 0.127-0.37253 0.19473-0.21166 0.0635-0.45296 0.0635-0.09313 0-0.19896-0.02117-0.10583-0.01693-0.20743-0.04233-0.1016-0.02963-0.1905-0.0635-0.08467-0.03387-0.14393-0.0635l0.1778-0.44026q0.07197 0.04233 0.22013 0.09313 0.1524 0.0508 0.3175 0.0508z' />
        <path d='m24.363 53.284h-1.8881v-0.41063q0.0508-0.04233 0.127-0.11007 0.0762-0.07197 0.16933-0.16087 0.09737-0.0889 0.2032-0.19473 0.10583-0.10583 0.20743-0.22013 0.14393-0.16086 0.24976-0.2921 0.11007-0.13123 0.1778-0.24553 0.07197-0.11853 0.10583-0.2286 0.03387-0.1143 0.03387-0.23283 0-0.08467-0.0381-0.1651-0.0381-0.08043-0.10583-0.1397-0.06773-0.0635-0.1651-0.09737-0.09313-0.0381-0.20743-0.0381-0.1524 0-0.2794 0.04657-0.12277 0.04657-0.25823 0.15663l-0.22014-0.38946q0.1778-0.127 0.3937-0.21166 0.22013-0.0889 0.50376-0.0889 0.18203 0 0.34713 0.0508 0.16933 0.0508 0.29633 0.1524 0.13123 0.09737 0.20743 0.24553 0.0762 0.14817 0.0762 0.3429 0 0.13546-0.0254 0.254-0.02117 0.1143-0.07197 0.23283-0.04657 0.1143-0.127 0.23706-0.0762 0.12277-0.18627 0.2667-0.08467 0.11007-0.18626 0.22436-0.09737 0.11007-0.19473 0.21166-0.09737 0.1016-0.19473 0.1905-0.09313 0.0889-0.16933 0.14817h1.2192z' />
        <path d='m26.199 51.798q0-0.38523 0.08043-0.67309 0.08043-0.2921 0.22436-0.4826 0.14816-0.1905 0.35136-0.28363 0.2032-0.09737 0.45296-0.09737 0.5334 0 0.82126 0.37676 0.28786 0.37253 0.28786 1.1599 0 0.38946-0.08043 0.68156-0.08043 0.28786-0.2286 0.4826-0.14393 0.1905-0.35136 0.28786-0.2032 0.09313-0.45296 0.09313-0.53763 0-0.82126-0.3937-0.28363-0.39793-0.28363-1.1515zm1.6764 0q0-0.06773-0.0042-0.127 0-0.0635-0.0042-0.12276l-1.0372 0.94403q0.13123 0.38523 0.47836 0.38523 0.27093 0 0.4191-0.25823 0.14817-0.25823 0.14817-0.82126zm-1.1345 0q0 0.0635 0 0.12277 0.0042 0.05503 0.0085 0.1143l1.0329-0.94403q-0.127-0.36406-0.47836-0.36406-0.27093 0-0.4191 0.26246-0.14393 0.25823-0.14393 0.80856z' />
        <path d='m30.726 53.284h-1.8881v-0.41063q0.0508-0.04233 0.127-0.11007 0.0762-0.07197 0.16933-0.16087 0.09737-0.0889 0.2032-0.19473 0.10583-0.10583 0.20743-0.22013 0.14393-0.16086 0.24976-0.2921 0.11007-0.13123 0.1778-0.24553 0.07197-0.11853 0.10583-0.2286 0.03387-0.1143 0.03387-0.23283 0-0.08467-0.0381-0.1651-0.0381-0.08043-0.10583-0.1397-0.06773-0.0635-0.1651-0.09737-0.09313-0.0381-0.20743-0.0381-0.1524 0-0.2794 0.04657-0.12277 0.04657-0.25823 0.15663l-0.22014-0.38946q0.1778-0.127 0.3937-0.21166 0.22013-0.0889 0.50376-0.0889 0.18203 0 0.34713 0.0508 0.16933 0.0508 0.29633 0.1524 0.13123 0.09737 0.20743 0.24553 0.0762 0.14817 0.0762 0.3429 0 0.13546-0.0254 0.254-0.02117 0.1143-0.07197 0.23283-0.04657 0.1143-0.127 0.23706-0.0762 0.12277-0.18627 0.2667-0.08467 0.11007-0.18626 0.22436-0.09737 0.11007-0.19473 0.21166-0.09736 0.1016-0.19473 0.1905-0.09313 0.0889-0.16933 0.14817h1.2192z' />
        <path d='m33.2 53.284h-1.8881v-0.41063q0.0508-0.04233 0.127-0.11007 0.0762-0.07197 0.16933-0.16087 0.09736-0.0889 0.2032-0.19473 0.10583-0.10583 0.20743-0.22013 0.14393-0.16086 0.24976-0.2921 0.11007-0.13123 0.1778-0.24553 0.07197-0.11853 0.10583-0.2286 0.03387-0.1143 0.03387-0.23283 0-0.08467-0.0381-0.1651-0.0381-0.08043-0.10583-0.1397-0.06773-0.0635-0.1651-0.09737-0.09313-0.0381-0.20743-0.0381-0.1524 0-0.2794 0.04657-0.12276 0.04657-0.25823 0.15663l-0.22013-0.38946q0.1778-0.127 0.3937-0.21166 0.22013-0.0889 0.50376-0.0889 0.18203 0 0.34713 0.0508 0.16933 0.0508 0.29633 0.1524 0.13123 0.09737 0.20743 0.24553 0.0762 0.14817 0.0762 0.3429 0 0.13546-0.0254 0.254-0.02117 0.1143-0.07197 0.23283-0.04657 0.1143-0.127 0.23706-0.0762 0.12277-0.18626 0.2667-0.08467 0.11007-0.18626 0.22436-0.09737 0.11007-0.19473 0.21166-0.09737 0.1016-0.19473 0.1905-0.09313 0.0889-0.16933 0.14817h1.2192z' />
        <path d='m33.908 52.819h0.64769v-1.85l-0.5588 0.42756-0.254-0.33866 0.96519-0.73659h0.37253v2.4976h0.635v0.46566h-1.8076z' />
        <path d='m38.399 52.878q0.1397 0 0.254-0.03387 0.11853-0.0381 0.19896-0.1016 0.08467-0.06773 0.13123-0.1524 0.04657-0.0889 0.04657-0.1905 0-0.127-0.05503-0.21166-0.0508-0.0889-0.14393-0.1397-0.09313-0.0508-0.22436-0.07197-0.13123-0.02117-0.28363-0.02117h-0.23706v-0.30056l0.71119-0.86783h-1.071v-0.46566h1.7272v0.38523l-0.74506 0.85089h0.09737q0.14816 0 0.28786 0.0508t0.24553 0.1524q0.11006 0.1016 0.17356 0.254 0.06773 0.1524 0.06773 0.35136 0 0.2413-0.1016 0.42333-0.09736 0.18203-0.26246 0.30903-0.16087 0.12276-0.37253 0.18626-0.21166 0.05927-0.43603 0.05927-0.1905 0-0.381-0.03387-0.1905-0.03387-0.3683-0.10583l0.14817-0.4572q0.0381 0.02117 0.1016 0.04233 0.06773 0.02117 0.14817 0.04233 0.08467 0.02117 0.16933 0.03387 0.0889 0.0127 0.17356 0.0127z' />
        <path d='m42.134 52.366q0 0.20743-0.08043 0.38523-0.0762 0.1778-0.21166 0.30903-0.13547 0.13123-0.32596 0.20743-0.18626 0.0762-0.40216 0.0762-0.22013 0-0.41486-0.06773-0.1905-0.07197-0.33443-0.21166-0.14393-0.1397-0.2286-0.35136-0.08467-0.21166-0.08467-0.49106 0-0.4191 0.14393-0.76199 0.14817-0.3429 0.37676-0.59266 0.2286-0.254 0.51223-0.4064 0.28363-0.15663 0.56303-0.19897l0.12277 0.43603q-0.22436 0.04233-0.4191 0.1397-0.19473 0.09313-0.34713 0.2286-0.1524 0.13547-0.25823 0.30056-0.1016 0.1651-0.1397 0.3429 0.08467-0.10583 0.254-0.1778 0.16933-0.0762 0.37253-0.0762 0.1905 0 0.3556 0.0635 0.1651 0.05927 0.28363 0.1778 0.12277 0.1143 0.1905 0.28363 0.07197 0.16933 0.07197 0.38523zm-0.5334 0.02117q0-0.2286-0.1397-0.34713-0.13547-0.11853-0.3683-0.11853-0.16933 0-0.3048 0.0762-0.13546 0.0762-0.19896 0.1778-0.0085 0.04657-0.0127 0.10583 0 0.05503 0 0.0889 0 0.1016 0.03387 0.19473 0.03387 0.09313 0.09737 0.1651 0.06773 0.06773 0.16087 0.11007 0.09313 0.0381 0.2159 0.0381 0.09737 0 0.1905-0.03387 0.09313-0.0381 0.1651-0.1016 0.07197-0.0635 0.1143-0.1524 0.04657-0.09313 0.04657-0.2032z' />
        <path d='m44.608 52.366q0 0.20743-0.08043 0.38523-0.0762 0.1778-0.21166 0.30903t-0.32596 0.20743q-0.18626 0.0762-0.40216 0.0762-0.22013 0-0.41486-0.06773-0.1905-0.07197-0.33443-0.21166-0.14393-0.1397-0.2286-0.35136-0.08467-0.21166-0.08467-0.49106 0-0.4191 0.14393-0.76199 0.14817-0.3429 0.37676-0.59266 0.2286-0.254 0.51223-0.4064 0.28363-0.15663 0.56303-0.19897l0.12277 0.43603q-0.22436 0.04233-0.4191 0.1397-0.19473 0.09313-0.34713 0.2286-0.1524 0.13547-0.25823 0.30056-0.1016 0.1651-0.1397 0.3429 0.08467-0.10583 0.254-0.1778 0.16933-0.0762 0.37253-0.0762 0.1905 0 0.3556 0.0635 0.1651 0.05927 0.28363 0.1778 0.12277 0.1143 0.1905 0.28363 0.07197 0.16933 0.07197 0.38523zm-0.5334 0.02117q0-0.2286-0.1397-0.34713-0.13546-0.11853-0.3683-0.11853-0.16933 0-0.3048 0.0762-0.13546 0.0762-0.19896 0.1778-0.0085 0.04657-0.0127 0.10583 0 0.05503 0 0.0889 0 0.1016 0.03387 0.19473 0.03387 0.09313 0.09737 0.1651 0.06773 0.06773 0.16086 0.11007 0.09313 0.0381 0.2159 0.0381 0.09737 0 0.1905-0.03387 0.09313-0.0381 0.1651-0.1016 0.07197-0.0635 0.1143-0.1524 0.04657-0.09313 0.04657-0.2032z' />
        <path d='m45.774 52.857q0.1397 0 0.25823-0.03387 0.11853-0.03387 0.2032-0.09737 0.0889-0.0635 0.13547-0.15663 0.0508-0.09313 0.0508-0.21166 0-0.23283-0.18626-0.36406-0.18203-0.13546-0.55033-0.13546-0.04657 0-0.11007 0.0042-0.05927 0-0.1143 0.0042-0.0635 0.0042-0.13123 0.0042v-1.5494h1.5325v0.508h-1.0583v0.57996q0.03387 0 0.06773-0.0042 0.02963 0 0.05503 0 0.02963-0.0042 0.0508-0.0042 0.21166 0 0.3937 0.0635 0.18203 0.05927 0.3175 0.18203 0.13547 0.12276 0.20743 0.30056 0.0762 0.17356 0.0762 0.38523 0 0.24976-0.09313 0.44026-0.09313 0.18627-0.254 0.31326-0.16087 0.127-0.37253 0.19473-0.21166 0.0635-0.45296 0.0635-0.09313 0-0.19896-0.02117-0.10583-0.01693-0.20743-0.04233-0.1016-0.02963-0.1905-0.0635-0.08467-0.03387-0.14393-0.0635l0.1778-0.44026q0.07197 0.04233 0.22013 0.09313 0.1524 0.0508 0.3175 0.0508z' />
      </g>
      <g strokeWidth='.26458'>
        <path d='m5.2331 57.904h-0.81105l-0.18627 0.60149h-0.53164l0.87702-2.7164h0.53164l0.87314 2.7164h-0.55881zm-0.68687-0.42687h0.58209l-0.27552-0.90806h-0.034925z' />
        <path d='m6.2498 55.816q0.089254-0.01552 0.19015-0.02716 0.10478-0.01552 0.20567-0.02328 0.10478-0.0078 0.20179-0.01164 0.097015-0.0039 0.17851-0.0039 0.19015 0 0.37254 0.03881 0.18627 0.03493 0.32985 0.12418 0.14746 0.08925 0.23284 0.2406 0.089254 0.15134 0.089254 0.37642 0 0.16687-0.034925 0.29104-0.031045 0.1203-0.089254 0.21343-0.058209 0.09313-0.14358 0.16298-0.081493 0.06597-0.17851 0.1203l0.65582 1.1875h-0.61314l-0.54716-1.0633h-0.31821v1.0633h-0.53164zm0.81493 0.40746q-0.081493 0-0.16299 0.0039-0.077612 0.0039-0.1203 0.01552v0.78776h0.20955q0.22119 0 0.3609-0.1009 0.1397-0.1009 0.1397-0.33373 0-0.17463-0.10866-0.27164-0.10866-0.1009-0.31821-0.1009z' />
        <path d='m9.9791 57.058 0.019403-0.42298h-0.023284l-0.43463 0.71015h-0.093134l-0.45403-0.71015h-0.019403l0.023284 0.3803v1.4902h-0.50448v-2.7164h0.5394l0.46567 0.75284h0.015522l0.46179-0.75284h0.53552v2.7164h-0.53164z' />
        <path d='m12.218 57.904h-0.81104l-0.18627 0.60149h-0.53164l0.87702-2.7164h0.53164l0.87314 2.7164h-0.55881zm-0.68687-0.42687h0.58209l-0.27552-0.90806h-0.03492z' />
        <path d='m13.712 56.899h-0.0194l0.02328 0.32209v1.2845h-0.50448v-2.7164h0.46567l0.93911 1.6609h0.0194l-0.03493-0.32597v-1.3349h0.50448v2.7164h-0.46567z' />
        <path d='m15.517 55.789q0.03492-0.0039 0.1203-0.01164 0.08925-0.0078 0.19791-0.01164 0.11254-0.0078 0.22896-0.01164 0.1203-0.0039 0.21343-0.0039 0.3609 0 0.59761 0.10478 0.23672 0.10478 0.37254 0.29104 0.1397 0.18239 0.19403 0.43463 0.05433 0.24836 0.05433 0.54328 0 0.26776-0.05433 0.52388-0.05433 0.25612-0.19791 0.45791-0.1397 0.19791-0.39194 0.32209-0.24836 0.1203-0.6403 0.1203-0.06209 0-0.1591-0.0039-0.09701-0.0039-0.19791-0.01164-0.1009-0.0039-0.19403-0.01164-0.09313-0.0039-0.14358-0.01164zm0.78 0.43463q-0.07373 0-0.14358 0.0078-0.06985 0.0039-0.10478 0.0078v1.82q0.01552 0.0039 0.04657 0.0078 0.03105 0 0.06209 0.0039 0.03493 0 0.06597 0.0039 0.03104 0 0.05045 0 0.22508 0 0.35702-0.08149 0.13582-0.08537 0.20179-0.22119 0.06597-0.13582 0.08537-0.30657 0.0194-0.17075 0.0194-0.34149 0-0.14746-0.0194-0.30657-0.01552-0.1591-0.08149-0.29104-0.06209-0.13194-0.19015-0.21731-0.12806-0.08537-0.34925-0.08537z' />
        <path d='m17.791 57.147q0-0.32985 0.06209-0.59373 0.06209-0.26388 0.19015-0.44239 0.12806-0.18239 0.31821-0.2794 0.19403-0.09701 0.45403-0.09701 0.2794 0 0.47343 0.10478t0.31433 0.29104q0.12418 0.18627 0.17851 0.44627 0.05821 0.26 0.05821 0.57045 0 0.66358-0.25612 1.04-0.25224 0.37254-0.76836 0.37254-0.2794 0-0.47731-0.10478-0.19403-0.10478-0.31821-0.29104-0.1203-0.18627-0.17463-0.44627-0.05433-0.26-0.05433-0.57045zm0.55881 0q0 0.19791 0.01552 0.36866 0.0194 0.17075 0.06597 0.29881 0.05045 0.12418 0.1397 0.19791 0.09313 0.07373 0.24448 0.07373 0.13582 0 0.22507-0.05433 0.08925-0.05821 0.14358-0.17463 0.05433-0.11642 0.07373-0.29104 0.02328-0.17851 0.02328-0.4191 0-0.19015-0.01552-0.3609-0.01164-0.17075-0.06209-0.29881-0.04657-0.12806-0.1397-0.20179-0.09313-0.07761-0.24836-0.07761-0.27164 0-0.36866 0.23672-0.09702 0.23672-0.09702 0.70239z' />
        <path d='m23.42 57.058 0.0194-0.42298h-0.02328l-0.43463 0.71015h-0.09313l-0.45403-0.71015h-0.0194l0.02328 0.3803v1.4902h-0.50448v-2.7164h0.5394l0.46567 0.75284h0.01552l0.46179-0.75284h0.53552v2.7164h-0.53164z' />
        <path d='m24.247 57.147q0-0.32985 0.06209-0.59373 0.06209-0.26388 0.19015-0.44239 0.12806-0.18239 0.31821-0.2794 0.19403-0.09701 0.45403-0.09701 0.2794 0 0.47343 0.10478t0.31433 0.29104q0.12418 0.18627 0.17851 0.44627 0.05821 0.26 0.05821 0.57045 0 0.66358-0.25612 1.04-0.25224 0.37254-0.76836 0.37254-0.2794 0-0.47731-0.10478-0.19403-0.10478-0.31821-0.29104-0.1203-0.18627-0.17463-0.44627-0.05433-0.26-0.05433-0.57045zm0.55881 0q0 0.19791 0.01552 0.36866 0.0194 0.17075 0.06597 0.29881 0.05045 0.12418 0.1397 0.19791 0.09314 0.07373 0.24448 0.07373 0.13582 0 0.22508-0.05433 0.08925-0.05821 0.14358-0.17463 0.05433-0.11642 0.07373-0.29104 0.02328-0.17851 0.02328-0.4191 0-0.19015-0.01552-0.3609-0.01164-0.17075-0.06209-0.29881-0.04657-0.12806-0.1397-0.20179-0.09314-0.07761-0.24836-0.07761-0.27164 0-0.36866 0.23672-0.09701 0.23672-0.09701 0.70239z' />
        <path d='m28.158 57.594h0.42687v0.77612q-0.12418 0.08925-0.31821 0.1397-0.19015 0.05045-0.39194 0.05045-0.25612 0-0.48896-0.07373-0.22896-0.07761-0.40358-0.24448-0.17463-0.17075-0.2794-0.43851-0.10478-0.27164-0.10478-0.65582 0-0.3997 0.11642-0.66746 0.1203-0.27164 0.30657-0.43463 0.18627-0.16687 0.41522-0.23672 0.22896-0.07373 0.45015-0.07373 0.23284 0 0.39194 0.03881 0.16298 0.03881 0.26776 0.08925v0.80716h-0.42687v-0.44239q-0.1203-0.0194-0.24836-0.0194-0.15134 0-0.28328 0.05821-0.12806 0.05433-0.22508 0.17075-0.09702 0.11642-0.15134 0.29493-0.05433 0.17463-0.05433 0.41522 0 0.20955 0.05045 0.38418 0.05045 0.17075 0.14746 0.29492 0.09702 0.12418 0.23672 0.19403 0.1397 0.06597 0.31821 0.06597 0.12806 0 0.24836-0.02328z' />
        <path d='m30.366 57.369h-0.87702v1.137h-0.53164v-2.7164h0.53164v1.106h0.87702v-1.106h0.53164v2.7164h-0.53164z' />
        <path d='m32.644 57.904h-0.81105l-0.18627 0.60149h-0.53164l0.87702-2.7164h0.53164l0.87314 2.7164h-0.55881zm-0.68687-0.42687h0.58209l-0.27552-0.90806h-0.03492z' />
      </g>
    </g>
  </svg>
)

const CreditCardSection = () => {
  const [hovered, setHovered] = React.useState(false)
  return (
    <BaseSeoSection
      reversed
      maxWidth='md'
      seoTitle='Pronto podrás gastar tus criptomonedas donde quieras y como quieras'
      seoSubtitle='Usa la revolución blockchain en lo que tú quieras 🍻'
      seoBody={(
        <Button
          id='credit-card-cta'
          variant='contained'
          href='/auth/register/?campaign=payment_card'
        >
          Regístrate en la lista de espera
        </Button>
      )}
      imageComponent={(
        <Box
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Box
            sx={(theme) => ({
              my: 2,
              mx: 'auto',
              width: 'fit-content',
              height: 'min(50vh, 400px)',
              borderRadius: 3,
              border: 2,
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.action.hover,
              boxShadow: hovered ? theme.shadows[24] : theme.shadows[8],
              transform: hovered ? 'rotate(10deg)' : 'rotate(-10deg)',
              transitionProperty: 'transform, box-shadow',
              transitionDuration: '1s',
              transitionTimingFunction: 'ease-out',
            })}
          >
            <CreditCard />
          </Box>
        </Box>
      )}
      sx={{
        '& > .MuiGrid-item:last-of-type': {
          overflow: 'visible',
        },
      }}
    />
  )
}

export default CreditCardSection
