import * as React from 'react'

import { Switch, styled } from '@mui/material'

import { BaseSeoSection } from 'shared/components'

import type { Theme } from '@mui/material'

const inputProps = { 'aria-label': 'cambiar la paleta de colores' }

const switchSize = 128

const textStyle = (theme: Theme) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: theme.typography.h2.fontSize,
  fontWeight: 400,
  transition: 'opacity',
  transitionDuration: '1s',
  transitionTimingFunction: 'ease-in-out',
})

const CeDeFiSwitch = styled(Switch)(({ theme }) => ({
  height: switchSize,
  width: 2 * switchSize,
  padding: switchSize / 4,
  overflow: 'visible',
  '& .MuiSwitch-switchBase': {
    margin: 0,
    padding: 0,
    transitionDuration: '1s',
    '& .MuiSwitch-input': {
      position: 'absolute',
      height: '100%',
      width: '100%',
      left: 0,
      top: 0,
    },
    '&.Mui-checked': {
      transform: `translateX(${switchSize}px)`,
      '& .MuiSwitch-thumb': {
        '&:before': {
          opacity: 0,
        },
        '&:after': {
          opacity: 1,
        },
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    height: switchSize,
    width: switchSize,
    color: theme.palette.primary.contrastText,
    background: `linear-gradient(${[
      '-45deg',
      theme.palette.primary.main,
      theme.palette.primary.dark,
      theme.palette.primary.main,
    ].join(',')})`,
    backgroundPosition: '0% 0%',
    backgroundSize: '400% 400%',
    animation: 'dynamic-bg 5s linear infinite',
    boxShadow: theme.shadows[12],
    '&:before': {
      content: "'CeFi'",
      opacity: 1,
      ...textStyle(theme),
    },
    '&:after': {
      content: "'DeFi'",
      opacity: 0,
      ...textStyle(theme),
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    background: `linear-gradient(${[
      '-45deg',
      theme.palette.primary.dark,
      theme.palette.primary.light,
      theme.palette.primary.main,
    ].join(',')})`,
    backgroundPosition: '0% 0%',
    backgroundSize: '400% 400%',
    animation: 'dynamic-bg 5s linear infinite',
    borderRadius: switchSize / 2,
    boxShadow: theme.shadows[8],
    transitionPropery: 'background-color',
    transitionDuration: '1s',
  },
}))

const CeDeFiSection = () => {
  const [defiOn, setDefiOn] = React.useState(true)

  const seoTitle = defiOn
    ? 'Tus llaves y resposabilidad en tus manos'
    : 'Tu bóveda segura para usarla cuando quieras'

  const seoSubtitle = defiOn
    ? 'Te ayudamos con custodia compartida para que uses finanzas descentralizadas'
    : 'Inicia tu aventura blockchain con la mejor experiencia en finanzas centralizadas'

  return (
    <BaseSeoSection
      reversed
      maxWidth='md'
      seoTitle={seoTitle}
      seoSubtitle={seoSubtitle}
      imageComponent={(
        <React.Fragment>
          <CeDeFiSwitch
            checked={defiOn}
            onClick={() => setDefiOn((current) => !current)}
            inputProps={inputProps}
          />
        </React.Fragment>
      )}
      sx={(theme) => ({
        '& > .MuiGrid-item:last-of-type': {
          overflow: 'visible',
          alignItems: 'center',
          [theme.breakpoints.up('sm')]: {
            alignItems: 'flex-start',
          },
        },
      })}
    />
  )
}

export default CeDeFiSection
