import type { PaletteMode, Shadows, ThemeOptions } from '@mui/material'
import type { ThemeConfig } from 'shared/contexts'

export const dynamicColors = {
  text: {
    'light': 'rgba(0, 0, 0, 0.87)',
    'dark': '#fff',
  },
  background: {
    'light': '#fdf7ff',
    'dark': '#150a30',
  },
  shadowColor: {
    'light': '270 11% 22%',
    'dark': '265 88% 66%',
  },
  mainTitleGradient: {
    'light': '-webkit-linear-gradient(120deg,hsl(247deg 93% 44%) 10%,hsl(258deg 90% 34%) 40%,hsl(269deg 87% 24%) 100%)',
    'dark': '-webkit-linear-gradient(120deg,hsl(292deg 69% 46%) 10%,hsl(281deg 66% 45%) 40%,hsl(270deg 63% 44%) 100%)',
  },
}

const themeColors = {
  'light': '#fdf7ff',
  'dark': '#21163a',
}

// eslint-disable-next-line max-len
const disabledGradient = '-webkit-linear-gradient(120deg,hsl(0deg 0% 44%) 10%,hsl(0deg 0% 34%) 40%,hsl(0deg 0% 24%) 100%)'

const lowShadow = [
  '0.7px 0.7px 0.7px hsl(var(--color-shadowColor) / 0.69)',
  '2.6px 2.6px 2.8px -5px hsl(var(--color-shadowColor) / 0.23)',
].join(',')

const midShadow = [
  '0.7px 0.7px 0.7px hsl(var(--color-shadowColor) / 0.72)',
  '1.1px 1.1px 1.2px -1.7px hsl(var(--color-shadowColor) / 0.54)',
  '4.4px 4.4px 4.7px -3.3px hsl(var(--color-shadowColor) / 0.36)',
  '13.2px 13.2px 14px -5px hsl(var(--color-shadowColor) / 0.18)',
].join(',')

const highShadow = [
  '0.5px 0.5px 0.5px hsl(var(--color-shadowColor) / 1)',
  '0.7px 0.7px 0.7px -0.7px hsl(var(--color-shadowColor) / 0.89)',
  '2.2px 2.3px 2.4px -1.4px hsl(var(--color-shadowColor) / 0.76)',
  '6.2px 6.5px 6.7px -2.1px hsl(var(--color-shadowColor) / 0.63)',
  '14.1px 14.7px 15.3px -2.9px hsl(var(--color-shadowColor) / 0.51)',
  '27.1px 28.3px 29.4px -3.6px hsl(var(--color-shadowColor) / 0.38)',
  '46.5px 48.5px 50.4px -4.3px hsl(var(--color-shadowColor) / 0.25)',
  '73.6px 76.8px 79.8px -5px hsl(var(--color-shadowColor) / 0.13)',
].join(',')

const shadows = (mode: PaletteMode) => [
  'none',
  ...Array(5).fill(lowShadow),
  ...Array(5).fill(midShadow),
  ...Array(10).fill(mode === 'light' ? highShadow : midShadow),
  ...Array(4).fill(highShadow),
] as Shadows

const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  const palette = {
    mode,
    action: {
      hoverOpacity: 0.16 * (mode === 'light' ? 1 : 2),
      selectedOpacity: 0.26 * (mode === 'light' ? 1 : 2),
      disabledOpacity: 0.8,
      focusOpacity: 0.48,
      activatedOpacity: 0.48,
    },
    primary: {
      main: '#69349e',
      dark: '#3c0872',
      light: '#9c5cf5',
    },
    secondary: {
      main: '#9e6934',
      dark: '#7f5b38',
      light: '#c49b73',
    },
    text: {
      primary: dynamicColors.text[mode],
    },
    background: {
      default: 'var(--color-background)',
      paper: 'var(--color-background)',
    },
    divider: mode === 'light' ? 'rgba(0, 0, 0, 0.26)' : 'rgba(255, 255, 255, 0.52)',
  }

  const modeShadows = shadows(mode)

  return {
    components: {
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            background: 'var(--color-mainTitleGradient)',
            boxShadow: modeShadows[8],
            ':hover': {
              boxShadow: modeShadows[8],
            },
            '&.Mui-disabled': {
              background: disabledGradient,
              color: dynamicColors.text.dark,
            },
          },
          outlinedPrimary: {
            color: mode === 'light' ? palette.primary.dark : dynamicColors.text.dark,
            borderColor: palette.primary.main,
          },
          textPrimary: {
            color: mode === 'light' ? palette.primary.main : palette.primary.light,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: mode === 'light' ? palette.primary.dark : dynamicColors.text.dark,
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          outlinedPrimary: {
            color: mode === 'light' ? palette.primary.dark : dynamicColors.text.dark,
            borderColor: palette.primary.main,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: mode === 'light' ? palette.primary.dark : palette.primary.light,
            textDecorationColor: mode === 'light' ? palette.primary.dark : palette.primary.light,
          },
        },
      },
    },
    palette: palette,
    shadows: modeShadows,
    typography: {
      button: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },
  }
}

const themes = {
  light: getDesignTokens('light'),
  dark: getDesignTokens('dark'),
}

export const muiThemeConfig: ThemeConfig = {
  dynamicColors,
  themeColors,
  themes,
}
