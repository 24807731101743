import * as React from 'react'

import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Box, Fade, IconButton, Modal, styled } from '@mui/material'

import SwipableCarouselView, { SwipableCarouselViewProps } from './swipable_carousel_view'

const ModalContainer = styled(Box)(({ theme }) => {
  const borderRadius: number =
    typeof theme.shape.borderRadius === 'number'
      ? 4 * theme.shape.borderRadius
      : 16

  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: theme.breakpoints.values.sm,
    maxHeight: '100vh',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    borderRadius: borderRadius,
  }
})

const Arrow = styled(IconButton)(() => ({
  width: 48,
  height: 48,
  position: 'absolute',
  top: 'calc((100% - 96px) / 2 + 24px)',
}))

type CarouselProps = Omit<SwipableCarouselViewProps, 'index' | 'onChangeIndex'> & {
  open: boolean
  onClose: () => void
  slideIndex: number
  setSlideIndex: (index: number) => void
}

export const Carousel = ({
  open,
  onClose,
  slideIndex,
  setSlideIndex,
  children,
  ...rest
}: CarouselProps) => {
  const decreaseIndex = () => setSlideIndex(slideIndex - 1)
  const increaseIndex = () => setSlideIndex(slideIndex + 1)
  const handleChangeIndex = (index: number) => setSlideIndex(index)

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <Fade in={open}>
        <ModalContainer>
          <SwipableCarouselView
            index={slideIndex}
            onChangeIndex={handleChangeIndex}
            {...rest}
          >
            {children}
          </SwipableCarouselView>
          <Arrow
            aria-label='retroceder'
            onClick={decreaseIndex}
            sx={{ left: 12 }}
          >
            <ArrowBack />
          </Arrow>
          <Arrow
            aria-label='avanzar'
            onClick={increaseIndex}
            sx={{ right: 12 }}
          >
            <ArrowForward />
          </Arrow>
        </ModalContainer>
      </Fade>
    </Modal>
  )
}

export default Carousel
