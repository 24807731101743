export type CryptoName = 'Bitcoin' | 'Ethereum' | 'Cardano' | 'Polkadot' | 'USD Tether' | 'USD Coin'

export const cryptoColorMap: { [key in CryptoName]: string } = {
  'Bitcoin': '#f7931a',
  'Ethereum': '#323a63',
  'Cardano': '#19384f',
  'Polkadot': '#e6007a',
  'USD Tether': '#0a7753',
  'USD Coin': '#3e88d5',
}

export const cryptoDescriptionMap: { [name in CryptoName]: React.ReactNode } = {
  'Bitcoin': (
    'Moneda digital libre y descentralizada que permite transacciones sin intermediarios.'
  ),
  'Ethereum': (
    'Plataforma global para aplicaciones descentralizadas que se ejecutan por medio de contratos inteligentes.'
  ),
  'Cardano': (
    'Plataforma que expande el concepto de contratos inteligentes de Ethereum permitiendo ejecutarlos en otras redes'
    + ' descentralizadas.'
  ),
  'Polkadot': (
    'Protocolo de código de abierto que permite la transferencia e interoperabilidad entre distintos blockchains.'
  ),
  'USD Tether': (
    'Moneda digital estable que está vinculada al dólar estadounidense con una relación 1:1. Su respaldo está'
    + ' garantizado por instituciones centralizadas.'
  ),
  'USD Coin': (
    'Moneda digital estable que está vinculada al dólar estadounidense con una relación 1:1. Su respaldo está'
    + ' garantizado por Coinbase.'
  ),
}

export type BlockchainName =
  'Bitcoin' |
  'Ethereum' |
  'Cardano' |
  'Polkadot' |
  'Tron' |
  'Algorand' |
  'Polygon'

export const cryptoBlockchains: { [name in CryptoName]: BlockchainName[] } = {
  'Bitcoin': [
    'Bitcoin',
  ],
  'Ethereum': [
    'Ethereum',
  ],
  'Cardano': [
    'Cardano',
  ],
  'Polkadot': [
    'Polkadot',
  ],
  'USD Tether': [
    'Ethereum',
    'Tron',
    'Algorand',
    'Polygon',
  ],
  'USD Coin': [
    'Ethereum',
    'Algorand',
    'Polygon',
  ],
}

export type Crypto = {
  name: CryptoName
  blockchains: BlockchainName[]
  description: React.ReactNode
  color: string
}

const createCrypto = (name: CryptoName): Crypto => ({
  name,
  color: cryptoColorMap[name],
  blockchains: cryptoBlockchains[name],
  description: cryptoDescriptionMap[name],
})

export const cryptoData: { [key in CryptoName]: Crypto } = {
  'Bitcoin': createCrypto('Bitcoin'),
  'Ethereum': createCrypto('Ethereum'),
  'Cardano': createCrypto('Cardano'),
  'Polkadot': createCrypto('Polkadot'),
  'USD Tether': createCrypto('USD Tether'),
  'USD Coin': createCrypto('USD Coin'),
}
