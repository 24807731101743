import * as React from 'react'

import { Box, Grid, alpha, styled, useTheme } from '@mui/material'

import { AppContainer, EarningsBalance, MainBalance } from 'shared/components'

import GraphWrapper from './graph_wrapper'

import type { TickerPrice } from 'shared/queries'

const height = 500
const width = height / 2
const padding = 15
const graphHeight = height / 2 - padding

const pixels = (i: number) => `${i}px`

const SmartphoneContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: pixels(2 * padding),
  padding: pixels(padding),
  height: '100%',
  width: '100%',
  flex: 1,
  backgroundColor: theme.palette.primary.dark,
  boxShadow:
    theme.shadows[12] + `, inset 0px 0px 2px 4px ${alpha(
      theme.palette.primary.main,
      2 * theme.palette.action.activatedOpacity,
    )}`,
  '::after': {
    content: '""',
    position: 'absolute',
    height: pixels(padding - 1),
    width: '50%',
    left: '50%',
    top: pixels(padding - 1),
    transform: 'translate(-50%, 0)',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: `0px 0px ${pixels(padding)} ${pixels(padding)}`,
  },
}))

const SmartphoneViewport = ({ children }: { children?: React.ReactNode }) => {
  const theme = useTheme()

  const baseStyle = {
    borderRadius: pixels(padding),
    height: '100%',
    width: '100%',
  }

  return (
    <SmartphoneContainer>
      <Box
        sx={{
          bgcolor: theme.palette.background.default,
          ...baseStyle,
        }}
      >
        <Grid
          container
          sx={{
            m: 0,
            pt: 3,
            pb: 1,
            px: 1,
            bgcolor: theme.palette.action.hover,
            '& .MuiTypography-h4': {
              fontSize: '1.3rem',
            },
            '& .MuiTypography-h6': {
              fontSize: '.9rem',
            },
            '& .MuiSvgIcon-fontSizeMedium': {
              fontSize: '1.2rem',
            },
            ...baseStyle,
          }}
        >
          {children}
        </Grid>
      </Box>
    </SmartphoneContainer>
  )
}

type SmartphoneDemoProps = {
  loading: boolean
  symbol: string
  tickerPrices: TickerPrice[]
}

export const SmartphoneDemo = ({
  loading,
  symbol,
  tickerPrices,
}: SmartphoneDemoProps) => (
  <Box
    sx={{
      height: pixels(height),
      width: pixels(width),
      mx: 'auto',
    }}
  >
    <SmartphoneViewport>
      <MainBalance
        loading={loading}
        currency='USD'
        assetAmount={1}
        assetUsdPrice={tickerPrices[tickerPrices.length - 1]?.openPrice}
        md={12}
        px={1}
        py={2}
      />
      <EarningsBalance
        loading={loading}
        currency='USD'
        assetAmount={1}
        assetUsdPrice={230.74}
        md={12}
        px={1}
        py={2}
      />
      <AppContainer
        sx={{
          height: graphHeight,
          py: 1,
          'text': {
            fontSize: '.85em',
          },
        }}
      >
        <GraphWrapper
          symbol={symbol}
          tickerPrices={tickerPrices}
        />
      </AppContainer>
    </SmartphoneViewport>
  </Box>
)

export default SmartphoneDemo
