/* eslint-disable max-len */
import * as React from 'react'

type FloatingBadgesProps = React.SVGProps<SVGSVGElement> & {
  onBadgeClick: (name: string) => void
}

const FloatingBadges = ({ onBadgeClick, ...rest }: FloatingBadgesProps) => (
  <svg
    height='100%'
    version='1.1'
    viewBox='0 0 266.07 143.7'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...rest}
  >
    <defs>
      <linearGradient
        id='btcGradient'
        x1='3974.2'
        x2='3972.4'
        y1='-594.34'
        y2='-593.12'
        gradientTransform='matrix(34.64,0,0,-38.76,-137591,-22964)'
        gradientUnits='userSpaceOnUse'
      >
        <stop
          stopColor='#fc9226'
          offset='0'
        />
        <stop
          stopColor='#fcb938'
          offset='1'
        />
      </linearGradient>
    </defs>
    <g
      onClick={() => onBadgeClick('Bitcoin')}
      cursor='pointer'
    >
      <animateMotion
        values='0,1;0,0;0,1'
        dur='3s'
        begin='-1s'
        repeatCount='indefinite'
      />
      <g transform='matrix(.46025 0 0 .46025 178.29 94.663)'>
        <path
          d='m52 1.44 35.9 20.71a10.79 10.79 0 0 1 5.39 9.33v41.43a10.79 10.79 0 0 1-5.39 9.33l-35.9 20.76a10.79 10.79 0 0 1-10.77 0l-35.84-20.76a10.77 10.77 0 0 1-5.39-9.33v-41.43a10.77 10.77 0 0 1 5.39-9.33l35.87-20.71a10.79 10.79 0 0 1 10.74 0z'
          fill='url(#btcGradient)'
        />
        <path
          d='m57.66 69a14.79 14.79 0 0 1-7.22 0.53c-0.63-0.08-1.69-0.28-2.82-0.51l-1.88 7-4.3-1.16 1.84-6.86-3.28-0.87-1.84 6.87-4.31-1.15 1.84-6.85-8.47-2.3 2.27-4.94 2.57 0.7a2.1 2.1 0 0 0 1.71 0 1.82 1.82 0 0 0 0.78-1.17l4.78-17.83a2.38 2.38 0 0 0-0.07-2.15c-0.36-0.72-1.55-1.12-2.23-1.3l-2.49-0.67 1.2-4.47 8.48 2.27 1.85-6.91 4.31 1.16-1.85 6.91 3.28 0.87 1.85-6.9 4.34 1.15-1.87 7c3.16 1.06 5.38 2.31 7 4.42 2.17 2.71 1.1 6.14 0.82 7a8 8 0 0 1-2.07 3.13 9.94 9.94 0 0 1-3 1.39 10.72 10.72 0 0 1 3.12 2.44 7.14 7.14 0 0 1 0.91 6.59c-0.82 2.84-2.17 5.53-5.25 6.61zm-8.94-15c-1.6-0.52-3.23-0.95-4.87-1.31l-2.48 9.31s2.84 0.83 4.79 1.2a14.23 14.23 0 0 0 5.19 0.14 3.79 3.79 0 0 0 3.11-2.92 3.74 3.74 0 0 0-1.3-4 14.32 14.32 0 0 0-4.44-2.35zm-0.14-4.64a10.9 10.9 0 0 0 4.74 0.11 3.49 3.49 0 0 0 2.73-2.79 3.86 3.86 0 0 0-1.94-4.21 14.28 14.28 0 0 0-4.11-1.67c-1.34-0.39-2.75-0.63-2.75-0.63l-2.25 8.31s2 0.6 3.58 0.96z'
          fill='#fdf7ff'
        />
      </g>
      <g
        transform='matrix(.25612 0 0 .25612 68.8 49.25)'
        opacity='.85'
      >
        <path
          d='m52 1.44 35.9 20.71a10.79 10.79 0 0 1 5.39 9.33v41.43a10.79 10.79 0 0 1-5.39 9.33l-35.9 20.76a10.79 10.79 0 0 1-10.77 0l-35.84-20.76a10.77 10.77 0 0 1-5.39-9.33v-41.43a10.77 10.77 0 0 1 5.39-9.33l35.87-20.71a10.79 10.79 0 0 1 10.74 0z'
          fill='url(#btcGradient)'
        />
        <path
          d='m57.66 69a14.79 14.79 0 0 1-7.22 0.53c-0.63-0.08-1.69-0.28-2.82-0.51l-1.88 7-4.3-1.16 1.84-6.86-3.28-0.87-1.84 6.87-4.31-1.15 1.84-6.85-8.47-2.3 2.27-4.94 2.57 0.7a2.1 2.1 0 0 0 1.71 0 1.82 1.82 0 0 0 0.78-1.17l4.78-17.83a2.38 2.38 0 0 0-0.07-2.15c-0.36-0.72-1.55-1.12-2.23-1.3l-2.49-0.67 1.2-4.47 8.48 2.27 1.85-6.91 4.31 1.16-1.85 6.91 3.28 0.87 1.85-6.9 4.34 1.15-1.87 7c3.16 1.06 5.38 2.31 7 4.42 2.17 2.71 1.1 6.14 0.82 7a8 8 0 0 1-2.07 3.13 9.94 9.94 0 0 1-3 1.39 10.72 10.72 0 0 1 3.12 2.44 7.14 7.14 0 0 1 0.91 6.59c-0.82 2.84-2.17 5.53-5.25 6.61zm-8.94-15c-1.6-0.52-3.23-0.95-4.87-1.31l-2.48 9.31s2.84 0.83 4.79 1.2a14.23 14.23 0 0 0 5.19 0.14 3.79 3.79 0 0 0 3.11-2.92 3.74 3.74 0 0 0-1.3-4 14.32 14.32 0 0 0-4.44-2.35zm-0.14-4.64a10.9 10.9 0 0 0 4.74 0.11 3.49 3.49 0 0 0 2.73-2.79 3.86 3.86 0 0 0-1.94-4.21 14.28 14.28 0 0 0-4.11-1.67c-1.34-0.39-2.75-0.63-2.75-0.63l-2.25 8.31s2 0.6 3.58 0.96z'
          fill='#fdf7ff'
        />
      </g>
      <g
        transform='matrix(.15448 0 0 .15448 146.44 5.3429)'
        opacity='.5'
      >
        <path
          d='m52 1.44 35.9 20.71a10.79 10.79 0 0 1 5.39 9.33v41.43a10.79 10.79 0 0 1-5.39 9.33l-35.9 20.76a10.79 10.79 0 0 1-10.77 0l-35.84-20.76a10.77 10.77 0 0 1-5.39-9.33v-41.43a10.77 10.77 0 0 1 5.39-9.33l35.87-20.71a10.79 10.79 0 0 1 10.74 0z'
          fill='url(#btcGradient)'
        />
        <path
          d='m57.66 69a14.79 14.79 0 0 1-7.22 0.53c-0.63-0.08-1.69-0.28-2.82-0.51l-1.88 7-4.3-1.16 1.84-6.86-3.28-0.87-1.84 6.87-4.31-1.15 1.84-6.85-8.47-2.3 2.27-4.94 2.57 0.7a2.1 2.1 0 0 0 1.71 0 1.82 1.82 0 0 0 0.78-1.17l4.78-17.83a2.38 2.38 0 0 0-0.07-2.15c-0.36-0.72-1.55-1.12-2.23-1.3l-2.49-0.67 1.2-4.47 8.48 2.27 1.85-6.91 4.31 1.16-1.85 6.91 3.28 0.87 1.85-6.9 4.34 1.15-1.87 7c3.16 1.06 5.38 2.31 7 4.42 2.17 2.71 1.1 6.14 0.82 7a8 8 0 0 1-2.07 3.13 9.94 9.94 0 0 1-3 1.39 10.72 10.72 0 0 1 3.12 2.44 7.14 7.14 0 0 1 0.91 6.59c-0.82 2.84-2.17 5.53-5.25 6.61zm-8.94-15c-1.6-0.52-3.23-0.95-4.87-1.31l-2.48 9.31s2.84 0.83 4.79 1.2a14.23 14.23 0 0 0 5.19 0.14 3.79 3.79 0 0 0 3.11-2.92 3.74 3.74 0 0 0-1.3-4 14.32 14.32 0 0 0-4.44-2.35zm-0.14-4.64a10.9 10.9 0 0 0 4.74 0.11 3.49 3.49 0 0 0 2.73-2.79 3.86 3.86 0 0 0-1.94-4.21 14.28 14.28 0 0 0-4.11-1.67c-1.34-0.39-2.75-0.63-2.75-0.63l-2.25 8.31s2 0.6 3.58 0.96z'
          fill='#fdf7ff'
        />
      </g>
    </g>
    <g
      onClick={() => onBadgeClick('Ethereum')}
      cursor='pointer'
    >
      <animateMotion
        values='0,-1;0,0;0,-1'
        dur='3s'
        repeatCount='indefinite'
      />
      <g transform='matrix(.45448 0 0 .45448 157.13 34.652)'>
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#323a63'
        />
        <path
          d='m47.84 29.45q6.77 11.22 13.49 22.36c0.38 0.63 0.37 1-0.33 1.36q-6.24 3.63-12.43 7.36a1.52 1.52 0 0 1-1.76 0q-6.14-3.66-12.3-7.28c-0.58-0.35-0.9-0.62-0.45-1.36q6.77-11.13 13.49-22.31s0.09-0.06 0.29-0.13z'
          fill='#fdf7ff'
        />
        <path
          d='m60.79 56.6c-1.58 2.7-3.55 5.14-5.33 7.71-2.33 3.34-4.72 6.65-7.08 10-0.34 0.49-0.58 1.1-1.22 0.2q-6.34-8.91-12.73-17.79a2.85 2.85 0 0 1-0.2-0.51 0.85 0.85 0 0 1 0.84 0.12c3.89 2.29 7.81 4.54 11.69 6.86a1.68 1.68 0 0 0 2-0.06c3.53-2.12 7.09-4.21 10.64-6.31 0.41-0.27 0.78-0.68 1.39-0.22z'
          fill='#fdf7ff'
        />
      </g>
      <g
        transform='matrix(.29447 0 0 .29447 45.51 101.81)'
        opacity='.85'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#323a63'
        />
        <path
          d='m47.84 29.45q6.77 11.22 13.49 22.36c0.38 0.63 0.37 1-0.33 1.36q-6.24 3.63-12.43 7.36a1.52 1.52 0 0 1-1.76 0q-6.14-3.66-12.3-7.28c-0.58-0.35-0.9-0.62-0.45-1.36q6.77-11.13 13.49-22.31s0.09-0.06 0.29-0.13z'
          fill='#fdf7ff'
        />
        <path
          d='m60.79 56.6c-1.58 2.7-3.55 5.14-5.33 7.71-2.33 3.34-4.72 6.65-7.08 10-0.34 0.49-0.58 1.1-1.22 0.2q-6.34-8.91-12.73-17.79a2.85 2.85 0 0 1-0.2-0.51 0.85 0.85 0 0 1 0.84 0.12c3.89 2.29 7.81 4.54 11.69 6.86a1.68 1.68 0 0 0 2-0.06c3.53-2.12 7.09-4.21 10.64-6.31 0.41-0.27 0.78-0.68 1.39-0.22z'
          fill='#fdf7ff'
        />
      </g>
      <g
        transform='matrix(.27325 0 0 .27325 228.7 16.631)'
        opacity='.5'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#323a63'
        />
        <path
          d='m47.84 29.45q6.77 11.22 13.49 22.36c0.38 0.63 0.37 1-0.33 1.36q-6.24 3.63-12.43 7.36a1.52 1.52 0 0 1-1.76 0q-6.14-3.66-12.3-7.28c-0.58-0.35-0.9-0.62-0.45-1.36q6.77-11.13 13.49-22.31s0.09-0.06 0.29-0.13z'
          fill='#fdf7ff'
        />
        <path
          d='m60.79 56.6c-1.58 2.7-3.55 5.14-5.33 7.71-2.33 3.34-4.72 6.65-7.08 10-0.34 0.49-0.58 1.1-1.22 0.2q-6.34-8.91-12.73-17.79a2.85 2.85 0 0 1-0.2-0.51 0.85 0.85 0 0 1 0.84 0.12c3.89 2.29 7.81 4.54 11.69 6.86a1.68 1.68 0 0 0 2-0.06c3.53-2.12 7.09-4.21 10.64-6.31 0.41-0.27 0.78-0.68 1.39-0.22z'
          fill='#fdf7ff'
        />
      </g>
      <g
        transform='matrix(.21829 0 0 .21829 222.3 65.915)'
        opacity='.3'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#323a63'
        />
        <path
          d='m47.84 29.45q6.77 11.22 13.49 22.36c0.38 0.63 0.37 1-0.33 1.36q-6.24 3.63-12.43 7.36a1.52 1.52 0 0 1-1.76 0q-6.14-3.66-12.3-7.28c-0.58-0.35-0.9-0.62-0.45-1.36q6.77-11.13 13.49-22.31s0.09-0.06 0.29-0.13z'
          fill='#fdf7ff'
        />
        <path
          d='m60.79 56.6c-1.58 2.7-3.55 5.14-5.33 7.71-2.33 3.34-4.72 6.65-7.08 10-0.34 0.49-0.58 1.1-1.22 0.2q-6.34-8.91-12.73-17.79a2.85 2.85 0 0 1-0.2-0.51 0.85 0.85 0 0 1 0.84 0.12c3.89 2.29 7.81 4.54 11.69 6.86a1.68 1.68 0 0 0 2-0.06c3.53-2.12 7.09-4.21 10.64-6.31 0.41-0.27 0.78-0.68 1.39-0.22z'
          fill='#fdf7ff'
        />
      </g>
    </g>
    <g
      onClick={() => onBadgeClick('Cardano')}
      cursor='pointer'
    >
      <animateMotion
        values='0,.5;0,-.5;0,.5'
        dur='3s'
        begin='-.5s'
        repeatCount='indefinite'
      />
      <g transform='matrix(.35645 0 0 .35645 90.90 101.373)'>
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#19384f'
        />
        <g fill='#fdf7ff'>
          <circle
            cx='72.113'
            cy='52.195'
            r='1.35'
          />
          <circle
            transform='rotate(60)'
            cx='94.01'
            cy='-14.309'
            r='1.35'
          />
          <circle
            transform='rotate(120)'
            cx='47.365'
            cy='-66.524'
            r='1.35'
          />
          <circle
            transform='scale(-1)'
            cx='-21.177'
            cy='-52.236'
            r='1.35'
          />
          <circle
            transform='rotate(240)'
            cx='-43.075'
            cy='14.267'
            r='1.35'
          />
          <circle
            transform='rotate(-60)'
            cx='3.57'
            cy='66.483'
            r='1.35'
          />
          <circle
            cx='64.261'
            cy='52.195'
            r='1.9'
          />
          <circle
            transform='rotate(60)'
            cx='86.158'
            cy='-14.309'
            r='1.9'
          />
          <circle
            transform='rotate(120)'
            cx='39.513'
            cy='-66.524'
            r='1.9'
          />
          <circle
            transform='scale(-1)'
            cx='-29.029'
            cy='-52.236'
            r='1.9'
          />
          <circle
            transform='rotate(240)'
            cx='-50.927'
            cy='14.267'
            r='1.9'
          />
          <circle
            transform='rotate(-60)'
            cx='-4.2819'
            cy='66.483'
            r='1.9'
          />
          <circle
            cx='46.645'
            cy='38.698'
            r='2.1'
          />
          <circle
            transform='rotate(60)'
            cx='68.543'
            cy='-27.806'
            r='2.1'
          />
          <circle
            transform='rotate(120)'
            cx='21.898'
            cy='-80.022'
            r='2.1'
          />
          <circle
            transform='scale(-1)'
            cx='-46.645'
            cy='-65.734'
            r='2.1'
          />
          <circle
            transform='rotate(240)'
            cx='-68.543'
            cy='.76975'
            r='2.1'
          />
          <circle
            transform='rotate(-60)'
            cx='-21.898'
            cy='52.985'
            r='2.1'
          />
          <circle
            cx='46.645'
            cy='28.179'
            r='1.5'
          />
          <circle
            transform='rotate(60)'
            cx='68.543'
            cy='-38.324'
            r='1.5'
          />
          <circle
            transform='rotate(120)'
            cx='21.898'
            cy='-90.54'
            r='1.5'
          />
          <circle
            transform='scale(-1)'
            cx='-46.645'
            cy='-76.252'
            r='1.5'
          />
          <circle
            transform='rotate(240)'
            cx='-68.543'
            cy='-9.7484'
            r='1.5'
          />
          <circle
            transform='rotate(-60)'
            cx='-21.898'
            cy='42.467'
            r='1.5'
          />
          <circle
            cx='54.942'
            cy='52.236'
            r='3.7'
          />
          <circle
            transform='rotate(60)'
            cx='76.839'
            cy='-14.267'
            r='3.7'
          />
          <circle
            transform='rotate(120)'
            cx='30.194'
            cy='-66.483'
            r='3.7'
          />
          <circle
            transform='scale(-1)'
            cx='-38.348'
            cy='-52.195'
            r='3.7'
          />
          <circle
            transform='rotate(240)'
            cx='-60.246'
            cy='14.309'
            r='3.7'
          />
          <circle
            transform='rotate(-60)'
            cx='-13.601'
            cy='66.524'
            r='3.7'
          />
        </g>
      </g>
      <g
        transform='matrix(.32207 0 0 .32207 33.33 8.8899)'
        opacity='.69'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#19384f'
        />
        <g fill='#fdf7ff'>
          <circle
            cx='72.113'
            cy='52.195'
            r='1.35'
          />
          <circle
            transform='rotate(60)'
            cx='94.01'
            cy='-14.309'
            r='1.35'
          />
          <circle
            transform='rotate(120)'
            cx='47.365'
            cy='-66.524'
            r='1.35'
          />
          <circle
            transform='scale(-1)'
            cx='-21.177'
            cy='-52.236'
            r='1.35'
          />
          <circle
            transform='rotate(240)'
            cx='-43.075'
            cy='14.267'
            r='1.35'
          />
          <circle
            transform='rotate(-60)'
            cx='3.57'
            cy='66.483'
            r='1.35'
          />
          <circle
            cx='64.261'
            cy='52.195'
            r='1.9'
          />
          <circle
            transform='rotate(60)'
            cx='86.158'
            cy='-14.309'
            r='1.9'
          />
          <circle
            transform='rotate(120)'
            cx='39.513'
            cy='-66.524'
            r='1.9'
          />
          <circle
            transform='scale(-1)'
            cx='-29.029'
            cy='-52.236'
            r='1.9'
          />
          <circle
            transform='rotate(240)'
            cx='-50.927'
            cy='14.267'
            r='1.9'
          />
          <circle
            transform='rotate(-60)'
            cx='-4.2819'
            cy='66.483'
            r='1.9'
          />
          <circle
            cx='46.645'
            cy='38.698'
            r='2.1'
          />
          <circle
            transform='rotate(60)'
            cx='68.543'
            cy='-27.806'
            r='2.1'
          />
          <circle
            transform='rotate(120)'
            cx='21.898'
            cy='-80.022'
            r='2.1'
          />
          <circle
            transform='scale(-1)'
            cx='-46.645'
            cy='-65.734'
            r='2.1'
          />
          <circle
            transform='rotate(240)'
            cx='-68.543'
            cy='.76975'
            r='2.1'
          />
          <circle
            transform='rotate(-60)'
            cx='-21.898'
            cy='52.985'
            r='2.1'
          />
          <circle
            cx='46.645'
            cy='28.179'
            r='1.5'
          />
          <circle
            transform='rotate(60)'
            cx='68.543'
            cy='-38.324'
            r='1.5'
          />
          <circle
            transform='rotate(120)'
            cx='21.898'
            cy='-90.54'
            r='1.5'
          />
          <circle
            transform='scale(-1)'
            cx='-46.645'
            cy='-76.252'
            r='1.5'
          />
          <circle
            transform='rotate(240)'
            cx='-68.543'
            cy='-9.7484'
            r='1.5'
          />
          <circle
            transform='rotate(-60)'
            cx='-21.898'
            cy='42.467'
            r='1.5'
          />
          <circle
            cx='54.942'
            cy='52.236'
            r='3.7'
          />
          <circle
            transform='rotate(60)'
            cx='76.839'
            cy='-14.267'
            r='3.7'
          />
          <circle
            transform='rotate(120)'
            cx='30.194'
            cy='-66.483'
            r='3.7'
          />
          <circle
            transform='scale(-1)'
            cx='-38.348'
            cy='-52.195'
            r='3.7'
          />
          <circle
            transform='rotate(240)'
            cx='-60.246'
            cy='14.309'
            r='3.7'
          />
          <circle
            transform='rotate(-60)'
            cx='-13.601'
            cy='66.524'
            r='3.7'
          />
        </g>
      </g>
      <g
        transform='matrix(.22663 0 0 .22663 0 90.773)'
        opacity='.5'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#19384f'
        />
        <g fill='#fdf7ff'>
          <circle
            cx='72.113'
            cy='52.195'
            r='1.35'
          />
          <circle
            transform='rotate(60)'
            cx='94.01'
            cy='-14.309'
            r='1.35'
          />
          <circle
            transform='rotate(120)'
            cx='47.365'
            cy='-66.524'
            r='1.35'
          />
          <circle
            transform='scale(-1)'
            cx='-21.177'
            cy='-52.236'
            r='1.35'
          />
          <circle
            transform='rotate(240)'
            cx='-43.075'
            cy='14.267'
            r='1.35'
          />
          <circle
            transform='rotate(-60)'
            cx='3.57'
            cy='66.483'
            r='1.35'
          />
          <circle
            cx='64.261'
            cy='52.195'
            r='1.9'
          />
          <circle
            transform='rotate(60)'
            cx='86.158'
            cy='-14.309'
            r='1.9'
          />
          <circle
            transform='rotate(120)'
            cx='39.513'
            cy='-66.524'
            r='1.9'
          />
          <circle
            transform='scale(-1)'
            cx='-29.029'
            cy='-52.236'
            r='1.9'
          />
          <circle
            transform='rotate(240)'
            cx='-50.927'
            cy='14.267'
            r='1.9'
          />
          <circle
            transform='rotate(-60)'
            cx='-4.2819'
            cy='66.483'
            r='1.9'
          />
          <circle
            cx='46.645'
            cy='38.698'
            r='2.1'
          />
          <circle
            transform='rotate(60)'
            cx='68.543'
            cy='-27.806'
            r='2.1'
          />
          <circle
            transform='rotate(120)'
            cx='21.898'
            cy='-80.022'
            r='2.1'
          />
          <circle
            transform='scale(-1)'
            cx='-46.645'
            cy='-65.734'
            r='2.1'
          />
          <circle
            transform='rotate(240)'
            cx='-68.543'
            cy='.76975'
            r='2.1'
          />
          <circle
            transform='rotate(-60)'
            cx='-21.898'
            cy='52.985'
            r='2.1'
          />
          <circle
            cx='46.645'
            cy='28.179'
            r='1.5'
          />
          <circle
            transform='rotate(60)'
            cx='68.543'
            cy='-38.324'
            r='1.5'
          />
          <circle
            transform='rotate(120)'
            cx='21.898'
            cy='-90.54'
            r='1.5'
          />
          <circle
            transform='scale(-1)'
            cx='-46.645'
            cy='-76.252'
            r='1.5'
          />
          <circle
            transform='rotate(240)'
            cx='-68.543'
            cy='-9.7484'
            r='1.5'
          />
          <circle
            transform='rotate(-60)'
            cx='-21.898'
            cy='42.467'
            r='1.5'
          />
          <circle
            cx='54.942'
            cy='52.236'
            r='3.7'
          />
          <circle
            transform='rotate(60)'
            cx='76.839'
            cy='-14.267'
            r='3.7'
          />
          <circle
            transform='rotate(120)'
            cx='30.194'
            cy='-66.483'
            r='3.7'
          />
          <circle
            transform='scale(-1)'
            cx='-38.348'
            cy='-52.195'
            r='3.7'
          />
          <circle
            transform='rotate(240)'
            cx='-60.246'
            cy='14.309'
            r='3.7'
          />
          <circle
            transform='rotate(-60)'
            cx='-13.601'
            cy='66.524'
            r='3.7'
          />
        </g>
      </g>
    </g>
    <g
      onClick={() => onBadgeClick('Polkadot')}
      cursor='pointer'
    >
      <animateMotion
        values='0,1;0,0;0,1'
        dur='3s'
        repeatCount='indefinite'
      />
      <g transform='matrix(.37721 0 0 .37721 102.79 56.115)'>
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#e6007a'
        />
        <g fill='#fdf7ff'>
          <ellipse
            cx='46.645'
            cy='31.931'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(60)'
            cx='68.543'
            cy='-34.573'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(120)'
            cx='21.898'
            cy='-86.789'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='scale(-1)'
            cx='-46.645'
            cy='-72.501'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(240)'
            cx='-68.543'
            cy='-5.9972'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(-60)'
            cx='-21.898'
            cy='46.219'
            rx='9.2246'
            ry='5.3915'
          />
        </g>
      </g>
      <g
        transform='matrix(.28431 0 0 .28431 184.66 -5.1312e-5)'
        opacity='.85'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#e6007a'
        />
        <g fill='#fdf7ff'>
          <ellipse
            cx='46.645'
            cy='31.931'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(60)'
            cx='68.543'
            cy='-34.573'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(120)'
            cx='21.898'
            cy='-86.789'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='scale(-1)'
            cx='-46.645'
            cy='-72.501'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(240)'
            cx='-68.543'
            cy='-5.9972'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(-60)'
            cx='-21.898'
            cy='46.219'
            rx='9.2246'
            ry='5.3915'
          />
        </g>
      </g>
      <g
        transform='matrix(.23423 0 0 .23423 142.11 87.663)'
        opacity='.5'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#e6007a'
        />
        <g fill='#fdf7ff'>
          <ellipse
            cx='46.645'
            cy='31.931'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(60)'
            cx='68.543'
            cy='-34.573'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(120)'
            cx='21.898'
            cy='-86.789'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='scale(-1)'
            cx='-46.645'
            cy='-72.501'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(240)'
            cx='-68.543'
            cy='-5.9972'
            rx='9.2246'
            ry='5.3915'
          />
          <ellipse
            transform='rotate(-60)'
            cx='-21.898'
            cy='46.219'
            rx='9.2246'
            ry='5.3915'
          />
        </g>
      </g>
    </g>
    <g
      onClick={() => onBadgeClick('USD Coin')}
      cursor='pointer'
    >
      <animateMotion
        values='0,0;0,-1;0,0'
        dur='3s'
        begin='-2s'
        repeatCount='indefinite'
      />
      <g transform='matrix(.39248 0 0 .39248 90.69 11.977)'>
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#3e88d5'
        />
        <g fill='#fdf7ff'>
          <path d='m40.71 74.58c-0.1 1.34-0.63 1.63-1.81 1.22a23.83 23.83 0 0 1-1-44.67c0.85-0.34 2-1.38 2.74 0.05a3 3 0 0 1-1.41 4 16.87 16.87 0 0 0-6.56 4.18 19.7 19.7 0 0 0 6 32c1.6 0.64 2.17 1.53 2.04 3.22z' />
          <path d='m48.61 66.91c0.09 2.11-0.21 2.29-2.25 2.28-1.27 0-1.93-0.48-1.74-1.78 0.26-1.7-0.42-2.46-2.11-2.93a6.62 6.62 0 0 1-4.82-5.31c-0.24-1.08 0.16-1.55 1.22-1.58 1.48-0.05 2.83-0.22 3.32 1.87 0.41 1.74 2.12 2.23 3.77 2.37a4.77 4.77 0 0 0 4.56-1.68 1.89 1.89 0 0 0 0.25-1.9c0.43-1.7-0.55-2.44-1.82-2.79-1.57-0.44-3.2-0.69-4.8-1-3.87-0.81-5.77-2.6-6-5.64a6.31 6.31 0 0 1 4.89-7 1.79 1.79 0 0 0 1.57-2.15c-0.08-2.21 0.87-2.86 3-2.31 1.14 0.29 1 1.18 1 2a2.19 2.19 0 0 0 1.77 2.71 5.69 5.69 0 0 1 3.75 3.69c0.28 0.78 0.93 1.89-0.32 2.21-1.07 0.28-2.66 0.82-3.28-0.67-1-2.46-2.94-2.8-5.17-2.59a3 3 0 0 0-3.07 2.8c-0.12 1.83 1 2.66 2.54 3.06 2.71 0.7 5.58 0.71 8.11 2.17a3.4 3.4 0 0 1 1.5 1.57c2.19 4.55 0.47 8.75-4.26 10.29a1.93 1.93 0 0 0-1.61 2.31z' />
          <path d='m65 68.52c-2.67 3.41-6.21 5.39-10 7-0.72 0.32-1.67 1-2.29-0.18a3.29 3.29 0 0 1 0.89-3.6c0.31-0.16 0.65-0.25 1-0.4 6.83-3.23 10.94-8.58 11.72-16.08 0.82-7.76-2.29-13.91-8.6-18.49a27.65 27.65 0 0 0-3.47-1.58 3.34 3.34 0 0 1-1.5-4.12c0.5-1 1.29-0.55 2-0.28a23.63 23.63 0 0 1 14.8 15.59 22 22 0 0 1 1 6.94 24.9 24.9 0 0 1-5.55 15.2z' />
        </g>
      </g>
      <g
        transform='matrix(.34321 0 0 .34321 32.57 59.758)'
        opacity='.85'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#3e88d5'
        />
        <g fill='#fdf7ff'>
          <path d='m40.71 74.58c-0.1 1.34-0.63 1.63-1.81 1.22a23.83 23.83 0 0 1-1-44.67c0.85-0.34 2-1.38 2.74 0.05a3 3 0 0 1-1.41 4 16.87 16.87 0 0 0-6.56 4.18 19.7 19.7 0 0 0 6 32c1.6 0.64 2.17 1.53 2.04 3.22z' />
          <path d='m48.61 66.91c0.09 2.11-0.21 2.29-2.25 2.28-1.27 0-1.93-0.48-1.74-1.78 0.26-1.7-0.42-2.46-2.11-2.93a6.62 6.62 0 0 1-4.82-5.31c-0.24-1.08 0.16-1.55 1.22-1.58 1.48-0.05 2.83-0.22 3.32 1.87 0.41 1.74 2.12 2.23 3.77 2.37a4.77 4.77 0 0 0 4.56-1.68 1.89 1.89 0 0 0 0.25-1.9c0.43-1.7-0.55-2.44-1.82-2.79-1.57-0.44-3.2-0.69-4.8-1-3.87-0.81-5.77-2.6-6-5.64a6.31 6.31 0 0 1 4.89-7 1.79 1.79 0 0 0 1.57-2.15c-0.08-2.21 0.87-2.86 3-2.31 1.14 0.29 1 1.18 1 2a2.19 2.19 0 0 0 1.77 2.71 5.69 5.69 0 0 1 3.75 3.69c0.28 0.78 0.93 1.89-0.32 2.21-1.07 0.28-2.66 0.82-3.28-0.67-1-2.46-2.94-2.8-5.17-2.59a3 3 0 0 0-3.07 2.8c-0.12 1.83 1 2.66 2.54 3.06 2.71 0.7 5.58 0.71 8.11 2.17a3.4 3.4 0 0 1 1.5 1.57c2.19 4.55 0.47 8.75-4.26 10.29a1.93 1.93 0 0 0-1.61 2.31z' />
          <path d='m65 68.52c-2.67 3.41-6.21 5.39-10 7-0.72 0.32-1.67 1-2.29-0.18a3.29 3.29 0 0 1 0.89-3.6c0.31-0.16 0.65-0.25 1-0.4 6.83-3.23 10.94-8.58 11.72-16.08 0.82-7.76-2.29-13.91-8.6-18.49a27.65 27.65 0 0 0-3.47-1.58 3.34 3.34 0 0 1-1.5-4.12c0.5-1 1.29-0.55 2-0.28a23.63 23.63 0 0 1 14.8 15.59 22 22 0 0 1 1 6.94 24.9 24.9 0 0 1-5.55 15.2z' />
        </g>
      </g>
      <g
        transform='matrix(.30098 0 0 .30098 237.99 98.603)'
        opacity='.69'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#3e88d5'
        />
        <g fill='#fdf7ff'>
          <path d='m40.71 74.58c-0.1 1.34-0.63 1.63-1.81 1.22a23.83 23.83 0 0 1-1-44.67c0.85-0.34 2-1.38 2.74 0.05a3 3 0 0 1-1.41 4 16.87 16.87 0 0 0-6.56 4.18 19.7 19.7 0 0 0 6 32c1.6 0.64 2.17 1.53 2.04 3.22z' />
          <path d='m48.61 66.91c0.09 2.11-0.21 2.29-2.25 2.28-1.27 0-1.93-0.48-1.74-1.78 0.26-1.7-0.42-2.46-2.11-2.93a6.62 6.62 0 0 1-4.82-5.31c-0.24-1.08 0.16-1.55 1.22-1.58 1.48-0.05 2.83-0.22 3.32 1.87 0.41 1.74 2.12 2.23 3.77 2.37a4.77 4.77 0 0 0 4.56-1.68 1.89 1.89 0 0 0 0.25-1.9c0.43-1.7-0.55-2.44-1.82-2.79-1.57-0.44-3.2-0.69-4.8-1-3.87-0.81-5.77-2.6-6-5.64a6.31 6.31 0 0 1 4.89-7 1.79 1.79 0 0 0 1.57-2.15c-0.08-2.21 0.87-2.86 3-2.31 1.14 0.29 1 1.18 1 2a2.19 2.19 0 0 0 1.77 2.71 5.69 5.69 0 0 1 3.75 3.69c0.28 0.78 0.93 1.89-0.32 2.21-1.07 0.28-2.66 0.82-3.28-0.67-1-2.46-2.94-2.8-5.17-2.59a3 3 0 0 0-3.07 2.8c-0.12 1.83 1 2.66 2.54 3.06 2.71 0.7 5.58 0.71 8.11 2.17a3.4 3.4 0 0 1 1.5 1.57c2.19 4.55 0.47 8.75-4.26 10.29a1.93 1.93 0 0 0-1.61 2.31z' />
          <path d='m65 68.52c-2.67 3.41-6.21 5.39-10 7-0.72 0.32-1.67 1-2.29-0.18a3.29 3.29 0 0 1 0.89-3.6c0.31-0.16 0.65-0.25 1-0.4 6.83-3.23 10.94-8.58 11.72-16.08 0.82-7.76-2.29-13.91-8.6-18.49a27.65 27.65 0 0 0-3.47-1.58 3.34 3.34 0 0 1-1.5-4.12c0.5-1 1.29-0.55 2-0.28a23.63 23.63 0 0 1 14.8 15.59 22 22 0 0 1 1 6.94 24.9 24.9 0 0 1-5.55 15.2z' />
        </g>
      </g>
      <g
        transform='matrix(.14339 0 0 .14339 81.31 84.751)'
        opacity='.5'
      >
        <path
          d='m87.9 22.15-35.9-20.71a10.79 10.79 0 0 0-10.77 0l-35.84 20.71a10.77 10.77 0 0 0-5.39 9.33v41.43a10.77 10.77 0 0 0 5.39 9.33l35.87 20.76a10.79 10.79 0 0 0 10.74 0l35.9-20.76a10.79 10.79 0 0 0 5.39-9.33v-41.43a10.79 10.79 0 0 0-5.39-9.33z'
          fill='#3e88d5'
        />
        <g fill='#fdf7ff'>
          <path d='m40.71 74.58c-0.1 1.34-0.63 1.63-1.81 1.22a23.83 23.83 0 0 1-1-44.67c0.85-0.34 2-1.38 2.74 0.05a3 3 0 0 1-1.41 4 16.87 16.87 0 0 0-6.56 4.18 19.7 19.7 0 0 0 6 32c1.6 0.64 2.17 1.53 2.04 3.22z' />
          <path d='m48.61 66.91c0.09 2.11-0.21 2.29-2.25 2.28-1.27 0-1.93-0.48-1.74-1.78 0.26-1.7-0.42-2.46-2.11-2.93a6.62 6.62 0 0 1-4.82-5.31c-0.24-1.08 0.16-1.55 1.22-1.58 1.48-0.05 2.83-0.22 3.32 1.87 0.41 1.74 2.12 2.23 3.77 2.37a4.77 4.77 0 0 0 4.56-1.68 1.89 1.89 0 0 0 0.25-1.9c0.43-1.7-0.55-2.44-1.82-2.79-1.57-0.44-3.2-0.69-4.8-1-3.87-0.81-5.77-2.6-6-5.64a6.31 6.31 0 0 1 4.89-7 1.79 1.79 0 0 0 1.57-2.15c-0.08-2.21 0.87-2.86 3-2.31 1.14 0.29 1 1.18 1 2a2.19 2.19 0 0 0 1.77 2.71 5.69 5.69 0 0 1 3.75 3.69c0.28 0.78 0.93 1.89-0.32 2.21-1.07 0.28-2.66 0.82-3.28-0.67-1-2.46-2.94-2.8-5.17-2.59a3 3 0 0 0-3.07 2.8c-0.12 1.83 1 2.66 2.54 3.06 2.71 0.7 5.58 0.71 8.11 2.17a3.4 3.4 0 0 1 1.5 1.57c2.19 4.55 0.47 8.75-4.26 10.29a1.93 1.93 0 0 0-1.61 2.31z' />
          <path d='m65 68.52c-2.67 3.41-6.21 5.39-10 7-0.72 0.32-1.67 1-2.29-0.18a3.29 3.29 0 0 1 0.89-3.6c0.31-0.16 0.65-0.25 1-0.4 6.83-3.23 10.94-8.58 11.72-16.08 0.82-7.76-2.29-13.91-8.6-18.49a27.65 27.65 0 0 0-3.47-1.58 3.34 3.34 0 0 1-1.5-4.12c0.5-1 1.29-0.55 2-0.28a23.63 23.63 0 0 1 14.8 15.59 22 22 0 0 1 1 6.94 24.9 24.9 0 0 1-5.55 15.2z' />
        </g>
      </g>
    </g>
  </svg>
)

export default FloatingBadges
