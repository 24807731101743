import * as React from 'react'

import { TickerPricesGraph } from 'shared/components'
import { DomainContext } from 'shared/contexts'
import { getTimeframeData } from 'shared/services'

import type { TickerPrice } from 'shared/queries'

type GraphWrapperProps = {
  symbol: string
  tickerPrices: TickerPrice[]
}

const GraphWrapper = ({ symbol, tickerPrices }: GraphWrapperProps) => {
  const { quoteSymbol } = React.useContext(DomainContext)
  const timeframeData = getTimeframeData(tickerPrices, symbol, quoteSymbol!)

  return (
    <TickerPricesGraph
      currency='USD'
      data={timeframeData.prices}
      ath={timeframeData.ath}
      atl={timeframeData.atl}
    />
  )
}

export default GraphWrapper
