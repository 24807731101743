import * as React from 'react'

import { Container, Grid, Paper, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'

import { Title } from 'shared/components'

import type { ContainerProps, PaperProps } from '@mui/material'

type StepProps = PaperProps & {
  index: number
  text: React.ReactNode
}

export const Step = ({
  index,
  text,
  sx,
}: StepProps) => (
  <Grid
    item
    xs={12}
    sm={6}
    md={3}
    sx={{
      transition: 'all .3s ease-out',
      '&:hover': {
        pt: 2,
        pb: 1,
      },
    }}
  >
    <Paper
      variant='elevation'
      elevation={12}
      sx={{
        p: 3,
        height: '100%',
        color: 'common.white',
        background: 'linear-gradient(-45deg,#69349e,#3c0872,#843bb0)',
        backgroundPosition: '0% 50%',
        backgroundSize: '400% 400%',
        animation: 'dynamic-bg 5s linear infinite',
        ...sx,
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        spacing={3}
      >
        <Typography
          variant='h1'
          component='span'
        >
          {index}
        </Typography>
        <Typography
          variant='h6'
          component='span'
        >
          {text}
        </Typography>
      </Stack>
    </Paper>
  </Grid>
)

type StepsSectionProps = ContainerProps

const StepsSection = ({
  maxWidth = 'lg',
  sx = {},
  ...rest
}: StepsSectionProps) => (
  <Container
    maxWidth={maxWidth}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 'min(30vh, 650px)',
      px: 1,
      py: 3,
      ...sx,
    }}
    {...rest}
  >
    <Title
      colorful
      textAlign='center'
      sx={{
        mt: 6,
        mb: 6,
      }}
    >
      Empieza a usar Sugarblock en menos de 2 minutos
    </Title>
    <Grid
      container
      alignItems='stretch'
      spacing={3}
      px={2}
    >
      <Helmet>
        <style type='text/css'>{`
          @keyframes dynamic-bg {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
        `}</style>
      </Helmet>
      <Step
        index={1}
        text='Crea tu cuenta Sugarblock de forma gratuita'
        sx={{ animationDelay: '0' }}
      />
      <Step
        index={2}
        text='Verifica tu identidad'
        sx={{ animationDelay: '.3s' }}
      />
      <Step
        index={3}
        text='Compra o deposita tus criptomonedas'
        sx={{ animationDelay: '.6s' }}
      />
      <Step
        index={4}
        text='Recibe recompensas semanales'
        sx={{ animationDelay: '.9s' }}
      />
    </Grid>
  </Container>
)

export default StepsSection
