import adrianPhoto from '../images/landing/testimonials/adrian.jpg'
import agustinPhoto from '../images/landing/testimonials/agustin.jpg'
import cristianPhoto from '../images/landing/testimonials/cristian.jpg'
import cristobalPhoto from '../images/landing/testimonials/cristobal.jpg'
import felipePhoto from '../images/landing/testimonials/felipe.jpg'
import feliperPhoto from '../images/landing/testimonials/feliper.jpg'
import franciscaPhoto from '../images/landing/testimonials/francisca.jpg'
import francisca2Photo from '../images/landing/testimonials/francisca2.jpg'
import francoPhoto from '../images/landing/testimonials/franco.jpg'
import hernanPhoto from '../images/landing/testimonials/hernan.jpg'
import ismaelPhoto from '../images/landing/testimonials/ismael.jpg'
import italoPhoto from '../images/landing/testimonials/italo.jpg'
import joseIgnacioPhoto from '../images/landing/testimonials/jose-ignacio.jpg'
import joseLuisPhoto from '../images/landing/testimonials/jose-luis.jpg'
import josePhoto from '../images/landing/testimonials/jose.jpg'
import josefinaPhoto from '../images/landing/testimonials/josefina.jpg'
import marianaPhoto from '../images/landing/testimonials/mariana.jpg'
import matiPhoto from '../images/landing/testimonials/matias.jpg'
import momoPhoto from '../images/landing/testimonials/momo.jpg'
import pamelaPhoto from '../images/landing/testimonials/pamela.jpg'
import pedroPhoto from '../images/landing/testimonials/pedro.jpg'
import sebastianPhoto from '../images/landing/testimonials/sebastian.jpg'
import valentinaPhoto from '../images/landing/testimonials/valentina.jpg'
import zazuPhoto from '../images/landing/testimonials/zazu.jpg'

import type { Testimonial } from 'shared/components'

export const testimonialsData: Testimonial[] = [
  {
    name: 'Jose',
    title: 'Bitcoiner',
    quote: 'Me gusta lo simple que es comprar criptomonedas 🙌',
    imageSrc: josePhoto,
  },
  {
    name: 'José Luis',
    title: 'Ingeniero',
    quote: 'No entiendo mucho de cripto, pero se me hace más facil con Sugarblock 👌',
    imageSrc: joseLuisPhoto,
  },
  {
    name: 'Cristóbal ',
    title: 'Noob',
    quote: 'Hice mi primera inversión en BTC en Sugarblock, fácil y rapido!',
    imageSrc: cristobalPhoto,
  },
  {
    name: 'Cristóbal',
    title: 'Degen',
    quote: 'Si bien no tienen mucha variedad, la gran experiencia lo compensa',
    imageSrc: momoPhoto,
  },
  {
    name: 'Felipe',
    title: 'Trader',
    quote: 'No sabía que podia rentabilizar sobre mis criptomonedas sin hacer nada',
    imageSrc: felipePhoto,
  },
  {
    name: 'Ismael',
    title: 'Businessman',
    quote: 'Tengo las critptos ahí y generan recompensas, me gusta y está muy bueno',
    imageSrc: ismaelPhoto,
  },
  {
    name: 'Hernán',
    title: 'Gamer',
    quote: 'Compré ETH y pasé los que tenía en Binance, está simple 10/10',
    imageSrc: hernanPhoto,
  },
  {
    name: 'Italo',
    title: 'Emprendedor',
    quote: 'Me encanta, tengo mis cardanos haciendo staking desde la primera versión 💯',
    imageSrc: italoPhoto,
  },
  {
    name: 'Francisca',
    title: 'Estudiante',
    quote: 'Súper para quienes nunca habíamos tenido criptomonedas!',
    imageSrc: francisca2Photo,
  },
  {
    name: 'Valentina',
    title: 'Empresaria',
    quote: 'Me la recomendaron y me encanta porque es muy fácil de usar!',
    imageSrc: valentinaPhoto,
  },
  {
    name: 'Francisca',
    title: 'Periodista',
    quote: 'Partí como usuaria y ahora estoy con el equipo y escribo artículos 🌱',
    imageSrc: franciscaPhoto,
  },
  {
    name: 'Adrián',
    title: 'Empresario',
    quote: 'Está súper!!',
    imageSrc: adrianPhoto,
  },
  {
    name: 'Mariana',
    title: 'Empresaria',
    quote: 'Me volví media adicta a ver el portafolio en la App cuando el mercado se mueve',
    imageSrc: marianaPhoto,
  },
  {
    name: 'Sebastián',
    title: 'Experto UX',
    quote: 'Recibir más crypto solo por tener crypto, increíble',
    imageSrc: sebastianPhoto,
  },
  {
    name: 'Pedro',
    title: 'Emprendedor',
    quote: 'Lo encontré muy fácil y muy seguro, gran alternativa para invertir',
    imageSrc: pedroPhoto,
  },
  {
    name: 'Felipe',
    title: 'Ingeniero',
    quote: 'Invertí en 3 pasos, muy fácil y confiable',
    imageSrc: feliperPhoto,
  },
  {
    name: 'Agustín',
    title: 'Aussie Traveler',
    quote: 'Muy rápido me gusta me meto siempre ⚡️',
    imageSrc: agustinPhoto,
  },
  {
    name: 'Josefina',
    title: 'Diseñadora',
    quote: 'Una plataforma súper segura y simple de usar para poder invertir en diferentes criptos',
    imageSrc: josefinaPhoto,
  },
  {
    name: 'Aranzazú',
    title: 'Abogada',
    quote: 'Me transmite mucha seguridad y es súper simple comprar',
    imageSrc: zazuPhoto,
  },
  {
    name: 'Matías',
    title: 'Arquitecto',
    quote: 'Rápido y simple para personas no financieras como yo',
    imageSrc: matiPhoto,
  },
  {
    name: 'Pamela',
    title: 'Empresaria',
    quote: 'Muy fácil de entender para los nuevos en las criptomonedas',
    imageSrc: pamelaPhoto,
  },
  {
    name: 'Cristián',
    title: 'Médico Nuclear',
    quote: 'Llegué por recomendación de mi hermano, y me gustó mucho',
    imageSrc: cristianPhoto,
  },
  {
    name: 'Franco',
    title: 'Hidrógeno Verde',
    quote: 'Es simple y la plataforma es agradable visualmente',
    imageSrc: francoPhoto,
  },
  {
    name: 'Jose Ignacio',
    title: 'Abogado',
    quote: 'Súper simple e intuitivo',
    imageSrc: joseIgnacioPhoto,
  },
]
